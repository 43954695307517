import { Injectable } from '@angular/core';
import {
  CanLoad,
  Router,
  CanActivateChild
} from '@angular/router';
import { PermissionsService } from '../authentication/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard implements CanLoad, CanActivateChild {
  constructor(
    private router: Router,
    private permissions: PermissionsService
  ) { }

  canLoad(): boolean {
    if (this.permissions.isauthenticated() && (this.permissions.isEmployee() || this.permissions.isStudent())) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  canActivateChild(): boolean {
    return this.canLoad();
  }
}
