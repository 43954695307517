<ngx-ui-loader
fgsColor="#002333"
pbColor="#002333"
text="Please wait.."
pbThickness="4"
textColor="#002333"
></ngx-ui-loader>

<div ngxUiLoaderBlurred blur="100">
  <!-- This page content will be blurred/frosted when foreground loader is showed -->
  <router-outlet></router-outlet>
</div>
