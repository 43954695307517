import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss']
})
export class MediaPlayerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) { }

  hlsBitrates: any;
  headers: any;
  url = 'https://content-prep24x7-dev.s3.ap-south-1.amazonaws.com/output-video/Lecture+03.cmfa';
  // url;

  // initPlayer() {
  //   try {
  //     const kalturaPlayer = KalturaPlayer.setup({
  //       targetId: 'player-placeholder-dynamic',
  //       provider: {
  //         partnerId: '4498333',
  //         uiConfId: '49596973'
  //       },
  //       playback: {
  //         autoplay: true
  //       }
  //     });
  //     kalturaPlayer.loadMedia({entryId: 'ENTRY_ID'});
  //   } catch (e) {
  //     console.error(e.message);
  //   }
  // }

  ngOnInit(): void {
    console.log("aabb");
    // this.route.queryParams.subscribe(
    //   params => {
    //     this.url = params.url;
    //   }
    // )
  }

}
