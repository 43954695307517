export const environment = {
  production: false,

  // LOGIN SETTINGS
  LOGIN_EXPIRY_TIME: 0,

  // CART SETTINGS
  CART_EXPIREY_TIME: 0,
  CART_ITEM_EXPIREY_TIME: 7,

  // MEDIA FILE SETTINGS
  static: 'assets',
  // media: 'http://127.0.0.1:8000',
  // media: 'https://aptcourse.s3-ap-south-1.amazonaws.com/media/',
  media: 'https://content-prep24x7-dev.s3.ap-south-1.amazonaws.com/media/',
  mediaFromBulkUpload:
    'https://content-prep24x7-dev.s3.ap-south-1.amazonaws.com/',

  videoMedia: 'https://content-prep24x7-dev.s3.ap-south-1.amazonaws.com/',
  vidMedia:
    'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/',
  aptinationmedia: 'https://aptination.s3.amazonaws.com/media/',
  zoomleaveUrl: 'http://localhost:4200',

  // PAYMENT SETTINGS
  // ROZORPAY_API_KEY: 'rzp_test_xBkzrGuRSVlOGp',
  ROZORPAY_API_KEY: 'rzp_test_NxRtptyaVuYfkE',

  // USER SETTINGS
  DEFAULT_USER_PROFILE:
    'https://aptination.s3.amazonaws.com/static/dev/assets/images/svg/user-img.svg',
  ALLOWED_IMAGE_FORMATS: ['image/png', 'image/jpeg'],
  PHONE_LENGTH: 10,
  OTP_LENGTH: 6,
  COUPON_CODE_LENGTH: [5, 10],
  PHONE_REGEX: /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/,
  NOTIFICATION_LENGTH: 5,

  // NETWORK SETTING
  // BASE_URL: 'http://127.0.0.1:8000',
  // BASE_URL: 'https://backend.aptination.com',
  // BASE_URL: 'https://apidev.aptcourse.com/api/',
  // BASE_URL: 'http://ec2-3-111-147-19.ap-south-1.compute.amazonaws.com:8082/api/',
  // BASE_URL_CMS: 'http://ec2-3-111-147-19.ap-south-1.compute.amazonaws.com:8081/api/',

  // BASE_URL: 'http://#lb#:5001/api/',
  // BASE_URL_CMS: 'http://#lb#:5000/api/',

  // BASE_URL: 'http://localhost:5001/api/',
  // BASE_URL_CMS: 'http://localhost:5000/api/',

  // // Production Endpoint` testing
  // BASE_URL: 'http://a2332ab027fae480b99ab8c2808f5d98-1380603701.ap-south-1.elb.amazonaws.com:5001/api/',

  // BASE_URL_CMS: 'http://a2332ab027fae480b99ab8c2808f5d98-1380603701.ap-south-1.elb.amazonaws.com:5000/api/',

  // Dev Endpoint
  // BASE_URL: 'http://localhost:5001/api/',

  // BASE_URL_CMS: 'http://localhost:5000/api/',

  // new server endpoint
  BASE_URL: 'http://nkonlinetest.com/user/api/',

  BASE_URL_CMS: 'http://nkonlinetest.com/admin/api/',

  ASSESSMENT_BASE_URL: 'https://devbackend.aptination.com',
  // ASSESSMENT_BASE_URL: 'http://127.0.0.1:8000',

  // SITE SETTINGS
  COUNTRY_CODE: 'IND',
  COUNTRY_ID: '1',
  DEFAULT_STATE: 8,

  // UI SETTINGS
  MAX_ASSESSMENT_AT_DASHBOARD: 3,
  MAX_ASSESSMENT_AT_HOME: 2,

  // BLOG SETTINGS
  BLOG_SLIDER_COUNT: 2,
  NO_OF_COLUMNS: 4,
};
