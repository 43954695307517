import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstantsService } from 'src/app/config/constants.service';
import { AdminService } from 'src/app/core/services/admin.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private FormBulderVar: FormBuilder,
    private constantsServiceVar: ConstantsService,
    private adminService: AdminService,
  ) { }

  contactUsForm: FormGroup;
  submitted = false;
  feedback = {
    errors: null,
    message: ''
  }
  get f() {
    return this.contactUsForm.controls;
  }
  onSubmit(){
    this.submitted = true;
    if(this.contactUsForm.value.userMessage.trim().length <=0){
      this.contactUsForm.controls.userMessage.reset();
      this.contactUsForm.controls.userMessage.setValue(null)
    }
    if(this.contactUsForm.invalid){
      return;
    }
    // this.miscellaneousServiceVar.showLoader('Sending Your Message');
    const contactObj = {
      name: this.contactUsForm.value.userName,
      email: this.contactUsForm.value.userEmail,
      phonenumber: String(this.contactUsForm.value.userNumber),
      message: this.contactUsForm.value.userMessage,
      organisation: this.contactUsForm.value.userOrg
    };
    this.adminService.sendUserContactReq(contactObj)
    .subscribe(
      (data: any)=>{
        this.submitted = false
        this.contactUsForm.reset()
        this.feedback.errors = false;
        // this.miscellaneousServiceVar.hideLoader()
        this.feedback.message = 'Your Message Sent Successfully';
        setTimeout(() => {
          this.feedback.errors = null;
          this.feedback.message = null;
        }, 5000);
      },
      (error)=>{
        this.feedback.errors = true;
        this.feedback.message = error.message;
        setTimeout(() => {
          this.feedback.errors = null;
          this.feedback.message = null;
        }, 5000);
        // this.miscellaneousServiceVar.hideLoader()
      }
    );
  }

  clearValidations() {
    this.feedback.errors = null;
    this.feedback.message = '';
  }
  createFormInstant(){
    this.contactUsForm = this.FormBulderVar.group({
      userName: ['', [Validators.required]],
      userEmail: ['', [Validators.required, Validators.pattern(this.constantsServiceVar.EMAIL_REGEXP)]],
      userNumber: ['', [Validators.required, Validators.pattern(this.constantsServiceVar.PHONE.pattern)]],
      userMessage: ['', [Validators.required]],
      userOrg: ['', [Validators.required]],
    })
  }

  ngOnInit(){
    this.createFormInstant();
    window.scrollTo(0, 0);
  }

}
