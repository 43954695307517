import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';


@Injectable()
export class NetworkRequestInterceptor implements HttpInterceptor {

  constructor(public cookie: CookieService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      const params = new HttpParams();
      return next.handle(request.clone({ headers, params }));
    }

    if (this.cookie.get('_l_a_t') && request.headers.get('skip')) {
        request = request.clone({
            headers: request.headers.delete('skip'),
            setHeaders: {
              Authorization: `Bearer ${this.cookie.get('_l_a_t')}`,
              'Content-Type': 'multipart/form-data',
            }
        });
      }

    else if (this.cookie.get('_l_a_t') && !request.headers.has('Accept')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.cookie.get('_l_a_t')}`,
          'Content-Type': 'application/json',
        }
      });
    } else if (this.cookie.get('_l_a_t') && request.headers.has('Accept')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.cookie.get('_l_a_t')}`
        }
      });
    } else if (!this.cookie.get('_l_a_t') && !request.headers.has('Accept')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        }
      });
    }
    // else if (!request.headers.has('Content-Type')) {
    //   request = request.clone({ headers: request.headers.delete('Content-Type', 'application/json') });
    //   request = request.clone({ headers: request.headers.set('Content-Type', 'multipart/form-data')})
    // }
    return next.handle(request);
  }
}
