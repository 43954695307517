<section class="pageFold1 pb-5 pb-lg-0">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-lg-8 my-auto">
				<div class="pageFold1Div text-white mb-lg-5">
					<h2 class="h1">Contact Us</h2>
					<nav aria-label="breadcrumb">
					  <ol class="breadcrumb rounded-0 m-0">
					    <li class="breadcrumb-item"><a href="#" routerLink="/" class="text-white">Home</a></li>
					    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
					  </ol>
					</nav>
				</div>
			</div>
			<!-- <div class="col-lg-2 offset-lg-1 d-none d-lg-block mt-auto">
				<div [routerLink]="['/']" style="cursor: pointer;" class="pageFold1ImageWrapper">
					<img src="assets/icons/prep.png" class="img-fluid">
				</div>
			</div> -->
		</div>
	</div>
</section>
<section class="contactPageFold2 pt-5 pb-5">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="pageHeading mb-5 text-center">
					<h2>Schedule a demo now!</h2>
					<span class="d-inline-block verLine">
						<span class="d-inline-block triangle"></span>
					</span>
				</div>
			</div>
			<div class="col-md-6">
				<div class="formContactDiv">
					<form [formGroup]="contactUsForm" action="">
						<div class="form-group">
							<label>Your Name</label>
							<div class="input-group">
				        <div class="input-group-prepend">
				          <div class="input-group-text">
				            <i class="mdi mdi-account"></i>
				          </div>
				        </div> 
				        <input name="userName" type="text" class="form-control" (click)="clearValidations()"
				        				placeholder="Your Name" formControlName="userName"
				        				[ngClass]="{ 'is-invalid': submitted && f.userName.errors }">

				        <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
	                <div *ngIf="f.userName.errors.required">Name is required</div>
	              </div>
				      </div>
						</div>
						<div class="form-group">
							<label>Your Email</label>
							<div class="input-group">
				        <div class="input-group-prepend">
				          <div class="input-group-text">
				            <i class="mdi mdi-email"></i>
				          </div>
				        </div> 
				        <input name="text" type="email" class="form-control" (click)="clearValidations()"
				        				placeholder="Your Email" formControlName="userEmail"
				        				[ngClass]="{ 'is-invalid': submitted && f.userEmail.errors }">
				        				
				        <div *ngIf="submitted && f.userEmail.errors" class="invalid-feedback">
	                <div *ngIf="f.userEmail.errors.required">Email is required</div>
	                <div *ngIf="f.userEmail.errors.pattern">Please Enter a valid email address</div>
	              </div>
				      </div>
						</div>
						<div class="form-group">
							<label>Contact Number</label>
							<div class="input-group">
				        <div class="input-group-prepend">
				          <div class="input-group-text">
				            <i class="mdi mdi-phone-settings-outline"></i>
				          </div>
				        </div> 
				        <input name="text" type="number" class="form-control" (click)="clearValidations()"
				        				placeholder="Your Contact Number" formControlName="userNumber"
				        				[ngClass]="{ 'is-invalid': submitted && f.userNumber.errors }">
				        				
				        <div *ngIf="submitted && f.userNumber.errors" class="invalid-feedback">
	                <div *ngIf="f.userNumber.errors.required">Contact Number is required</div>
	                <div *ngIf="f.userNumber.errors.pattern">Please Enter Correct Number</div>
	              </div>
				      </div>
						</div>
						<div class="form-group">
							<label>Your Organization</label>
							<div class="input-group">
				        <div class="input-group-prepend">
				          <div class="input-group-text">
				            <i class="mdi mdi-factory"></i>
				          </div>
				        </div> 
				        <input name="text" type="email" class="form-control" (click)="clearValidations()"
				        				placeholder="Your Organization" formControlName="userOrg"
				        				[ngClass]="{ 'is-invalid': submitted && f.userOrg.errors }">
				        				
				        <div *ngIf="submitted && f.userOrg.errors" class="invalid-feedback">
	                <div *ngIf="f.userOrg.errors.required">Organization is required</div>
	              </div>
				      </div>
						</div>
						<div class="form-group">
							<label>Message</label>
							<div class="input-group">
				        <div class="input-group-prepend">
				          <div class="input-group-text">
				            <i class="mdi mdi-email-edit-outline"></i>
				          </div>
				        </div> 
				        <textarea placeholder="Your Message" class="form-control" rows="5" (click)="clearValidations()"
				        					formControlName="userMessage" [ngClass]="{ 'is-invalid': submitted && f.userMessage.errors }"></textarea>

				        <div *ngIf="submitted && f.userMessage.errors" class="invalid-feedback">
	                <div *ngIf="f.userMessage.errors.required">Message is required</div>
	              </div>
				      </div>
						</div>
						<div class="form-group text-center">
							<button class="btn btn-info w-100 text-white" (click)="onSubmit()">Submit</button>
						</div>
						<div class="d-block mt-3 mb-3 text-white p-3 h5 rounded shadow invalid-feedback bg-danger" *ngIf="feedback.message && feedback.errors">
							<p class="m-0">{{feedback.message}}</p>
						</div>
						<div class="d-block mt-3 mb-3 text-white p-3 h5 rounded shadow valid-feedback bg-success" *ngIf="feedback.message && !feedback.errors">
							<p class="m-0">{{feedback.message}}</p>
						</div>
					</form>
				</div>
			</div>
			<div class="col-md-4 offset-md-1 my-auto">
				<div class="row mb-md-5 mb-3 align-items-center">
					<div class="col-2 text-right">
						<i class="mdi h2 text-brand-color mdi-google-maps"></i>
					</div>
					<div class="col-10">
						<p class="m-0">
							Wittychamps Pvt Ltd., <br>
							
						</p>
					</div>
				</div>
				<div class="row mb-md-5 mb-3 align-items-center">
					<div class="col-2 text-right">
						<i class="mdi h2 text-brand-color mdi-phone"></i>
					</div>
					<div class="col-10">
						<p class="m-0">
							Support: <a class="text-dark" href="tel:+911204114299">+91 1204114299</a>,<br>
							Sales: <a class="text-dark" href="tel:+918700518827">+91 8700518827</a>
						</p>
					</div>
				</div>
				<div class="row mb-md-5 mb-3 align-items-center">
					<div class="col-2 text-right">
						<i class="mdi h2 text-brand-color mdi-email"></i>
					</div>
					<div class="col-10">
						<p class="m-0">
							<a class="text-dark" href="mailTo:wittychamps@gmail.com">wittychamps@gmail.com</a>
						</p>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-2 text-right">
						<i class="mdi h2 text-brand-color mdi-web"></i>
					</div>
					<div class="col-10">
						<p class="m-0">
							<a class="text-dark" href="https://wittychamps.com/" target="_blank">https://wittychamps.com/</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<app-footer></app-footer>