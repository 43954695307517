import { Injectable } from '@angular/core';

export class school {
    constructor(
        public id: string,
        public name: string,
        public address: string,
        public schoolCode: string,
        public type: string
    ) { }
}


export class Profile {
    constructor(
        public id: string,
        public username: string,
        public role: string,
        public name: string,
        public surname: string,
        public email: string,
        public profileImage: string,
        public contactNumber: string,
        public grade: string,
        public competencylevel: string,
        public school: school
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class ProfileAdapter {

    constructor() { }

    adapt(user: any): Profile {
        return new Profile(
            user.id,
            user.username,
            user.role,
            user.name,
            user.surname,
            user.email,
            user.profileImage,
            user.contactNumber,
            user.grade,
            user.competencylevel,
            new school(
                user.school ? user.school[0].id : null,
                user.school ? user.school[0].name : null,
                user.school ? user.school[0].address : null,
                user.school ? user.school[0].schoolCode : null,
                user.school ? user.school[0].type : null
            )
        )
    }
}