import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { PermissionsService } from './core/authentication/permissions.service';
import { ProfileService } from './core/authentication/user-profile.service';
import { UtilsService } from './core/services/utils.service';
import { RoleSwitchModeService } from './modules/dashboard/services/role-switch-mode.service';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  subscription: Subscription;
  title = 'aptinationlmsfrontend';
  constructor(
    private router: Router,
    private permissionsvc: PermissionsService,
    private profilesvc: ProfileService,
    private roleswitchmodesvc: RoleSwitchModeService,
    private cookie: CookieService,
    private utils: UtilsService,
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (this.permissionsvc.isauthenticated() && event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if (browserRefresh) {
          this.profilesvc.refreshProfileData().subscribe(
            data => {
              localStorage.setItem('userProfile', JSON.stringify(data));
              this.profilesvc.setUserProfileValue(data);
              this.roleswitchmodesvc.updateRoleMode(data['role']);
              const token = this.cookie.get('_l_a_t');
              const decodeToken = this.utils.decodeToken(token);
              const instituteType = decodeToken['instituteType'];
              this.roleswitchmodesvc.updateinstituteType(instituteType);
            },
            error=>{
              console.log('failed');
            }
          );
        }
      }
    });
  }

  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    console.log('back button pressed');
    event.preventDefault(); 
    // if ((window.location.pathname == '/' || window.location.pathname == '/login') && this.permissionsvc.isStudent()) {
    //   this.router.navigate(['/student'], {replaceUrl:true});
    //   console.log('student');
    // }

    // if ((window.location.pathname == '/' || window.location.pathname == '/login') && this.permissionsvc.isEmployee()) {
    //   this.router.navigate(['/employee'], {replaceUrl:true});
    //   console.log('employee');
    // }

    // if ((window.location.pathname == '/' || window.location.pathname == '/login') && this.permissionsvc.isAptcourseAdmin()) {
    //   this.router.navigate(['/superAdmin'], {replaceUrl:true});
    // }
    // else if ((window.location.pathname == '/' || window.location.pathname == '/login') && (this.permissionsvc.isSchoolAdmin() || this.permissionsvc.isOrganizationAdmin() || this.permissionsvc.isCoachingAdmin())) {
    //   this.router.navigate(['/superAdmin/institute-admin-mode'], {replaceUrl:true});
    // }
    // else if ((window.location.pathname == '/' || window.location.pathname == '/login') && (this.permissionsvc.isSchoolteacher() || this.permissionsvc.isCoachingFaculty())) {
    //   this.router.navigate(['/instructor'], {replaceUrl:true});
    // }
    // else if ((window.location.pathname == '/' || window.location.pathname == '/login') && (this.permissionsvc.isOrgInstructor())) {
    //   this.router.navigate(['/dashboard'], {replaceUrl:true});
    // }
  }

  ngOnInit() {
    console.log("current url ", window.location.pathname);
    // if (!this.permissionsvc.isauthenticated()) {
    //   this.router.navigate(['/']);
    // } 
    // if(this.permissionsvc.isAptcourseAdmin()) {
    //   this.router.navigate(['/superAdmin']);
    // }
    // else if(this.permissionsvc.isSchoolAdmin() || this.permissionsvc.isCoachingAdmin() || this.permissionsvc.isOrganizationAdmin()) {
    //   this.router.navigate(['/superAdmin/institute-admin-mode']);
    // }
    // else if((window.location.pathname == '/' || window.location.pathname == '/login') && (this.permissionsvc.isSchoolteacher() || this.permissionsvc.isCoachingFaculty())) {
    //   this.router.navigate(['/instructor']);
    // }
    // else if((window.location.pathname == '/' || window.location.pathname == '/login') && this.permissionsvc.isOrgInstructor()) {
    //   this.router.navigate(['/dashboard']);
    // }

    // if ((window.location.pathname == '/' || window.location.pathname == '/login') && this.permissionsvc.isStudent()) {
    //   this.router.navigate(['/student']);
    // }

    // if ((window.location.pathname == '/' || window.location.pathname == '/login') && this.permissionsvc.isEmployee()) {
    //   this.router.navigate(['/employee']);
    // }

  }
}
