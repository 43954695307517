import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../authentication/auth.service';
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private cookie: CookieService,
    private utils: UtilsService,
    private auth: AuthService
  ) { }

  isauthenticated(): boolean {
    const token = this.cookie.get('_l_a_t');
    if (!token) {
      return false;
    }

    const date = this.utils.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }

    if (date.valueOf() > new Date().valueOf()) {
      return true;
    } else {
      this.auth.logout();
      return false;
    }
  }

  isAptcourseAdmin() {
    // console.log("aabbbbvv");
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['role'];
        if (userType.includes('admin') || userType.includes('superadmin')) {
          console.log("superadminabab");
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }


  isSchoolAdmin() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        if (userType.includes('principal') && instituteType === 'school') {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }

  isOrganizationAdmin() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        if (userType.includes('principal') && instituteType === 'organization') {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }

  isCoachingAdmin() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        if (userType.includes('principal') && instituteType === 'coaching') {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }


  isSchoolteacher() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        if (userType.includes('teacher')) {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }

  isCoachingFaculty() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        if (userType.includes('teacher') && instituteType === 'coaching') {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }

  isOrgInstructor() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    console.log("decodeTokenaa", decodeToken);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        if (userType.includes('teacher') && instituteType === 'organization') {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }

  isStudent() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        console.log("studentaa", decodeToken, userType);
        if (userType.includes('student')) {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }

  isEmployee() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        const instituteType = decodeToken['instituteType'];
        if (userType.includes('student') && instituteType === 'organization') {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }

  isParent() {
    const token = this.cookie.get('_l_a_t');
    const decodeToken = this.utils.decodeToken(token);
    // Check user type only if authenticated
    if (this.isauthenticated()) {
      try {
        const userType = decodeToken['cmsrolenames'];
        if (userType === 'parent') {
          return true;
        }
      } catch (e) {
        this.auth.logout();
        return false;
      }
    } else {
      return false;
    }

  }
}
