<header>
  <h1>Terms and Conditions</h1>
</header>
<main>
  <div class="row">
    <!-- Overview -->
    <div class="col-lg-12">
      <h2>Overview</h2>
      <p>
        These terms and conditions constitute a binding contract as per the
        provisions and rules under the Information Technology Act of 2000, by
        and between the operator of the website, being <b>nkonlinetest</b>, a
        brand under M G Techno Savvy Private Limited, a company duly
        incorporated under the provisions of the Companies Act, 1956
        (hereinafter referred to as ‘Company’) and having its registered office
        at C-58, N. K Tower, Lalkothi Scheme, Jaipur, Rajasthan, 302015, India,
        and You. The Company is the authorized licensee and reseller of online
        courses
        <a href="http://www.nkonlinetest.com/">http://www.nkonlinetest.com/</a
        >(hereinafter referred to as ‘Website’).
      </p>
      <p>
        This Agreement for the Terms and Conditions of use of the Company’s
        Website/s (hereinafter referred to as ‘Agreement’) describes certain
        terms and conditions to access and use the Website/s of the Company by a
        visitor or a registered user and order products (hereinafter referred to
        as the “End User”, “You” or “Your”)
      </p>
    </div>

    <!-- Trademark -->
    <div class="col-lg-12">
      <h2>Trademarks</h2>
      <p>
        The trademarks, names, logos and service marks (collectively
        “trademarks”) displayed on this website are trademarks of the respective
        owners. Nothing contained on this website should be construed as
        granting any license or right to You to use any trademark without the
        prior written permission of the owner.
      </p>
    </div>

    <!-- External links -->
    <div class="col-lg-12">
      <h2>External Links</h2>
      <p>
        External links may be provided for your convenience, but they are beyond
        the control of the Company and Website and no representation is made as
        to their content. Use or reliance on any external links and the content
        thereon provided is at your own risk.
      </p>
    </div>

    <!-- Warrenties -->
    <div class="col-lg-12">
      <h2>Warranties</h2>
      <p>
        The Company and Website makes no warranties, representations, statements
        or guarantees (whether express, implied in law or residual) regarding
        the website.
      </p>
    </div>

    <!-- Disclaimers of Liability -->
    <div class="col-lg-12">
      <h2>Disclaimer of Liability</h2>
      <p>
        The Company and Website shall not be responsible for and disclaims all
        liability for any loss, liability, damage (whether direct, indirect or
        consequential), personal injury or expense of any nature whatsoever
        which may be suffered by you or any third party (including your
        company), as a result of or which may be attributable, directly or
        indirectly, to your access and use of the website, any information
        contained on the website, your or your company’s personal information or
        material and information transmitted over our system. In particular,
        neither the Company nor the Website or any third party or data or
        content provider shall be liable in any way to you or to any other
        person, firm or corporation whatsoever for any loss, liability, damage
        (whether direct or consequential) , personal injury or expense of any
        nature whatsoever arising from any delays, inaccuracies, errors in, or
        omission of any share price information or the transmission thereof, or
        for any actions taken in reliance thereon or occasioned thereby or by
        reason of non-performance or interruption, or termination thereof.
      </p>
    </div>

    <!-- Conflicts of terms -->
    <div class="col-lg-12">
      <h2>Conflict of Terms</h2>
      <p>
        If there is a conflict or contradiction between the provisions of these
        website terms and conditions and any other relevant terms and
        conditions, policies or notices, the other relevant terms and
        conditions, policies or notices which relate specifically to a
        particular section or module of the website shall prevail in respect of
        your use of the relevant section or module of the website.
      </p>
    </div>

    <!-- Severability -->
    <div class="col-lg-12">
      <h2>Severability</h2>
      <p>
        Any provision of any relevant terms and conditions, policies and
        notices, which is or becomes unenforceable in any jurisdiction, whether
        due to being void, invalidity, illegality, unlawfulness or for any
        reason whatever, shall, in such jurisdiction only and only to the extent
        that it is so unenforceable, be treated as void and the remaining
        provisions of any relevant terms and conditions, policies and notices
        shall remain in full force and effect.
      </p>
    </div>

    <!-- Applicable Laws (Choice of Venue and Forum) -->
    <div class="col-lg-12">
      <h2>Applicable Laws (Choice of Venue and Forum)</h2>
      <p>
        Use of this website shall in all respects be governed by the laws India,
        regardless of the laws that might be applicable under principles of
        conflicts of law. The parties agree that the courts located in Jaipur,
        Rajasthan, India shall have exclusive jurisdiction over all
        controversies arising under this agreement and You agree that venue is
        proper in those courts.
      </p>
    </div>

    <!-- Information Disclaimer -->
    <div class="col-lg-12">
      <h2>Information Disclaimer</h2>
      <p>
        The information (including, without limitation, advice and
        recommendations) on the website is intended solely as a general
        educational aid. Your use of the site is subject to the additional
        disclaimers and caveats that may appear throughout the website. The
        Company and Website and its agents assume no responsibilities for any
        consequence relating directly or indirectly to any action or inaction
        you take based on the information, services, or other material on the
        website. While we shall strive to keep the information on the website
        accurate, complete, and up-to-date, we cannot guarantee, and will not be
        responsible for, any damage or loss related to the accuracy,
        completeness, or timeliness of the information on the website.
      </p>
    </div>

    <!-- Legal Notice -->
    <div class="col-lg-12">
      <h2>Legal Notice</h2>
      <p>
        All content of this Internet site is protected by worldwide copyright
        laws. You may download content only for your personal use for
        non-commercial purposes but modification or further reproduction of the
        content is not permitted. The content may otherwise not be copied or
        used in any way. The owners of this site will use reasonable efforts to
        include up-to-date and accurate information in this Internet site, but
        makes no representations, warranties, or assurances as to the accuracy,
        currency, or completeness of the information provided. The owners of
        this site shall not be liable for any damages or injury resulting from
        your access to, or inability to access, this Internet site, or from your
        reliance on any information provided at this Internet site. This site is
        for residents of and/or domiciled in India. This Internet site may
        provide links or references to other sites but the owners of this site
        have no responsibility for the content of such other sites and shall not
        be liable for any damages or injury arising from that content.<br />
      </p>

      <p>
        Any links to other sites are provided as merely a convenience to the
        users of this Internet site. Any personally identifiable information in
        electronic communications to this Internet site is governed by this
        site’s Privacy Policy. The Company and Website shall be free to use or
        copy all other information in any such communications, including any
        ideas, inventions, concepts, techniques or know-how disclosed therein,
        for any purposes. Such purposes may include disclosure to third parties
        and/or developing, manufacturing and/or marketing goods or services. The
        sender of any communications to this Internet site or otherwise to the
        owners of this site shall be responsible for the content and information
        contained therein, including its truthfulness and accuracy.
      </p>
    </div>

    <!-- Shipping Policy  -->
    <div class="col-lg-12">
      <h2>Shipping Policy</h2>
      <p>
        Online classes: The subscription will start immediately. Parents /
        students can book their classes immediately after purchasing the
        subscription.
      </p>
    </div>

    <!-- Refund Policy -->
    <div class="col-lg-12">
      <h2>Refund Policy</h2>
      <p>
        The refund is applicable for our products/services only until it’s
        dispatched or the login details for online access to test material etc.
        are disclosed/dispatched to you. <br />
      </p>
      <p>
        Please include your order number (sent to you via email after your
        order) and do tell us why you’re requesting a refund. We take customer
        feedback very seriously and use it to constantly improve our products
        and quality of service.<br />
      </p>
      <p>
        All refunds, if eligible, will be processed within 15 (Fifteen) business
        days of receiving cancellation request and mode of refund would be same
        through which the original transaction was done.<br />
      </p>
      <p>
        For refund, a user has to send a mail on Email Id:
        <a href="mailto:info.nkonlinetest@gmail.com"
          >info.nkonlinetest@gmail.com</a
        >
        making a refund request regarding the purchase the user has made. The
        refund request should be made mentioning a valid reason, within a week
        of purchase.
      </p>
    </div>

    <!-- Cancellation Policy  -->
    <div class="col-lg-12">
      <h2>Cancellation Policy</h2>
      <p>
        For cancellation the student has to make a request with a valid reason.
      </p>
    </div>

    <!-- Payment Processing -->
    <div class="col-lg-12">
      <h2>Payment Processing</h2>
      <p>
        We use Third Party Payment Gateway to process payments securely. We as a
        merchant shall be under no liability whatsoever in respect of any loss
        or damage arising directly or indirectly out of the decline of
        authorization for any Transaction, on Account of the Cardholder having
        exceeded the preset limit mutually agreed by us with our acquiring bank
        from time to time.
      </p>
    </div>

    <!-- Communication -->
    <div class="col-lg-12">
      <h2>Communication</h2>
      <p>
        If a user fills a form, participates in a contest, or purchases product,
        user agrees to receive communication from the company or its
        communication partners. The Company or its communication partners may
        Call the user over the phone, send electronic mails (email) and Short
        Message to user Mobile Numbers (SMS) wherever necessary for the ease of
        communication with the user. The content of the email and SMS would be
        controlled by Company and Website and may not be copied anyway. If user
        wishes to opt-out, user can get in touch with the company and will be
        unsubscribed from all communications. Until user opts-out, user will
        continue to receive communication from the Company or its communication
        partners.”
      </p>
    </div>

    <!-- Modification of Terms -->
    <div class="col-lg-12">
      <h2>Modification of Terms</h2>
      <p>
        The Company reserves the right to change the terms and conditions
        mentioned here in at any time. Users are bound by the current version of
        the terms and conditions.
      </p>
    </div>

    <!-- Acceptance of terms -->
    <div class="col-lg-12">
      <h2>Acceptance of Terms</h2>
      <p>
        By accessing and using this Website, users accept that they have read,
        understood, and agreed to abide by these terms and conditions.
      </p>
    </div>
  </div>
</main>
