import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { ConstantsService } from '../../config/constants.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../http/error-handler.service';
import { Observable } from 'rxjs';
// import { SwitchChildService } from 'src/app/modules/user/services/switch-child.service';
import { PermissionsService } from '../authentication/permissions.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private cookie: CookieService,
    private http: HttpClient,
    private consts: ConstantsService,
    private errorHandler: ErrorHandlerService,
    private permissions: PermissionsService,
    private router: Router
  ) {}
  nativeGlobal() {
    return window;
  }
  isParent = this.permissions.isParent();

  getHeaderOption(): any {
    const token = this.cookie.get('_l_a_t');
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }).set('Access-Control-Allow-Origin', '*'),
    };
  }

  getLiveSessionHeaderOption(): any {
    const token = 'srznIbuE1GwqZ06sc85z9XUXtzWJJWFepPU7wfbWY';
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }).set('Access-Control-Allow-Origin', '*'),
    };
  }

  profileCapture(data) {
    return this.http
      .put(`${this.consts.captureProfile}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getsubscriptionHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  schoolList(page) {
    return this.http.get(
      `${this.consts.schoolListUrl}?page=${page}`,
      this.getHeaderOption()
    );
  }

  schoolsList(page) {
    return this.http.get(
      `${this.consts.schoolListUrl}?page=${page}&type=school`,
      this.getHeaderOption()
    );
  }

  organizationsList(page) {
    return this.http.get(
      `${this.consts.schoolListUrl}?page=${page}&type=organization`,
      this.getHeaderOption()
    );
  }

  coachingList(page) {
    return this.http.get(
      `${this.consts.schoolListUrl}?page=${page}&type=coaching`,
      this.getHeaderOption()
    );
  }

  searchSchool(data: any) {
    return this.http
      .post(this.consts.searchSchoolApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  searchJobResult(data: any) {
    return this.http
      .post(`${this.consts.searchJobListingApiUrl}/savelisting`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchAllJobResult() {
    return this.http
      .get(`${this.consts.searchJobListingApiUrl}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  searchUser(data: any) {
    return this.http
      .post(`${this.consts.createAdminApiUrl}/search`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  schoolDetails(data: any) {
    return this.http.get(
      `${this.consts.schoolListUrl}/${data}`,
      this.getHeaderOption()
    );
  }

  assignClassTeacher(id: any, data: any) {
    return this.http
      .post(`${this.consts.schoolListUrl}/${id}/teachers`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  subscriptionList() {
    return this.http.get(
      this.consts.subscriptionListApiUrl,
      this.getsubscriptionHeader()
    );
  }

  gradesList() {
    return this.http.get(this.consts.gradeListApiUrl, this.getHeaderOption());
  }

  instituteGradesList(schoolId) {
    const gradeListApiUrl = `${this.consts.gradeListApiUrl}?school=${schoolId}`;
    return this.http.get(gradeListApiUrl, this.getHeaderOption());
  }

  studentsList(data: any) {
    return this.http.get(
      `${this.consts.createAdminApiUrl}?${data}`,
      this.getHeaderOption()
    );
  }

  usersInfo(username: any) {
    return this.http.get(
      `${this.consts.createAdminApiUrl}/${username}`,
      this.getHeaderOption()
    );
  }

  // To add school to the database using admin role
  addSchool(data: any) {
    return this.http
      .post(this.consts.addSchoolApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  // Validate user while adding through Add School form
  validateUser(data: any): Observable<any> {
    return this.http
      .post(this.consts.validateUserApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createAdmin(data: any) {
    return this.http
      .post(this.consts.signupApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  // createAdmin(schoolId: any, data: any) {
  //   return this.http.post(`${this.consts.schoolListUrl}/${schoolId}/schoolprincipal`, data)
  //     .pipe(
  //       catchError(this.errorHandler.handleError)
  //     );
  // }

  //Update password from profile page
  updatePassword(data: any) {
    return this.http
      .put(this.consts.updatePasswordApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  editProfile(data: any) {
    return this.http
      .put(this.consts.editProfileApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  editProfilePic(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(this.consts.editProfilePicApiUrl, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  editProfilePicParentChild(data: any, username: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(
        `${this.consts.editProfilePicApiUrl}?username=${username}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  bulkRegister(data: any) {
    return this.http
      .post(`${this.consts.bulkRegisterApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  uploadCSVFile(data: any) {
    const token = this.cookie.get('_l_a_t');
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
      }),
    };
    return this.http
      .post(`${this.consts.uploadCSVFile}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  bulkUploadQuestions(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/bulkuploadquestion`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  editBulkUploadQuestions(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/updatebulkuploadquestion`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  bulkAddquestioninassessment(id: any, data: any, course_id: any) {
    const headers = new HttpHeaders().set('courseid', course_id);
    const options = { headers: headers };
    return this.http
      .post(
        `${this.consts.examApiUrl}/bulkAddquestioninassessment/${id}`,
        data,
        options
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  studentPasswordReset(username: any, data: any) {
    return this.http
      .put(`${this.consts.createAdminApiUrl}/${username}/password`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  scheduleQuiz(data: any) {
    return this.http
      .post(this.consts.scheduleQuizApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  questionContent(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/questioncontent`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  createQuestion(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(`${this.consts.examApiUrl}/question`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateQuestionImage(id, image) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.examApiUrl}/question/${id}/image`, image, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  // Remove question image
  removeQuestionImage(id, isRemove) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(
        `${this.consts.examApiUrl}/question/${id}/image`,
        isRemove,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateSolutionImage(id, image) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.examApiUrl}/solution/${id}/image`, image, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  // Remove solution image
  removeSolutionImage(id, isRemove) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(
        `${this.consts.examApiUrl}/solution/${id}/image`,
        isRemove,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateQuestionContent(text, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.examApiUrl}/questioncontent/${id}`, text, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateSolutionText(text, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.examApiUrl}/solution/${id}`, text, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateNumericalSolutionText(text, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(
        `${this.consts.examApiUrl}/numericalsolution/${id}`,
        text,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateFillUpSolutionText(text, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.examApiUrl}/fillupsolution/${id}`, text, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateMcqOptionText(text, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.examApiUrl}/mcqtestcase/${id}`, text, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateMcqCorrectOption(text) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.examApiUrl}/mcqtestcase/correct`, text, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  solutionData(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(`${this.consts.examApiUrl}/solution`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  mcqTestCase(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(`${this.consts.examApiUrl}/mcqtestcase`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  fillupSolution(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/fillupsolution`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  booleanTypeSolution(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/booleansolution`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  numericalTypeSolution(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/numericalsolution`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  assertionTypeSolution(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/assertionsolution`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  scheduleQuizImpersonate(data: any, username: any) {
    return this.http
      .post(`${this.consts.scheduleQuizApiUrl}?username=${username}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addLiveSessionandQuizToSchedule(id: any, data: any) {
    return this.http
      .put(`${this.consts.scheduleQuizApiUrl}/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getScheduledQuizList(page: any) {
    return this.http.get(
      `${this.consts.scheduleQuizApiUrl}?page=${page}`,
      this.getHeaderOption()
    );
  }

  getScheduledQuizListImpersonate(page: any, username: any) {
    return this.http.get(
      `${this.consts.scheduleQuizApiUrl}?page=${page}&username=${username}`,
      this.getHeaderOption()
    );
  }

  getScheduledQuizListGrade(page: any, grade, section) {
    return this.http.get(
      `${this.consts.scheduleQuizApiUrl}?page=${page}&grade=${grade}&section=${section}`,
      this.getHeaderOption()
    );
  }

  getScheduledQuizListGradeImpersonate(
    page: any,
    username: any,
    grade,
    section
  ) {
    return this.http.get(
      `${this.consts.scheduleQuizApiUrl}?page=${page}&username=${username}&grade=${grade}&section=${section}`,
      this.getHeaderOption()
    );
  }

  addQuiz(id: any, data: any) {
    return this.http
      .post(`${this.consts.scheduleQuizApiUrl}/${id}/quiz`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addQuizImpersonate(id: any, data: any, username: any) {
    return this.http
      .post(
        `${this.consts.scheduleQuizApiUrl}/${id}/quiz?username=${username}`,
        data
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchTopics(subjectid: any, page: any) {
    return this.http.get(
      `${this.consts.fetchTopicsApiUrl}?subject=${subjectid}&page=${page}`,
      this.getHeaderOption()
    );
  }

  fetchTopicById(id: any) {
    return this.http.get(
      `${this.consts.fetchTopicsApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  fetchTopicsBySubjectId(id: any) {
    return this.http.get(
      `${this.consts.fetchSubjectsApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  fetchVideosBySubjectId(id: any) {
    return this.http.get(
      `${this.consts.fetchSubjectsApiUrl}/${id}/videos`,
      this.getHeaderOption()
    );
  }

  fetchDocsBySubjectId(id: any) {
    return this.http.get(
      `${this.consts.fetchSubjectsApiUrl}/${id}/documents`,
      this.getHeaderOption()
    );
  }

  fetchGamesBySubjectId(id: any) {
    return this.http.get(
      `${this.consts.fetchSubjectsApiUrl}/${id}/games`,
      this.getHeaderOption()
    );
  }

  fetchGamesByCourseId(id: any) {
    return this.http.get(
      `${this.consts.courseApiUrl}/${id}/games`,
      this.getHeaderOption()
    );
  }

  fetchGamesByTopicId(id: any) {
    return this.http.get(
      `${this.consts.fetchTopicsApiUrl}/${id}/games`,
      this.getHeaderOption()
    );
  }

  addSubject(data: any) {
    return this.http
      .post(this.consts.createSubject, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  linkExistingVideo(id, data: any) {
    return this.http
      .put(`${this.consts.updateVideoApiUrl}/linkexistingvideo/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchSubjects(page: any) {
    return this.http.get(
      `${this.consts.fetchSubjectsApiUrl}?page=${page}`,
      this.getHeaderOption()
    );
  }

  fetchSubjectsList() {
    return this.http.get(
      `${this.consts.fetchSubjectsApiUrl}/all?select=_id,name`,
      this.getHeaderOption()
    );
  }

  addTopic(courseId, data: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/${courseId}/topic`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteTopic(topicId) {
    return this.http
      .delete(`${this.consts.createTopic}/${topicId}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  searchTopic(data: any) {
    return this.http
      .post(`${this.consts.createTopic}/search`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateTopic(id: any, data: any) {
    return this.http
      .put(`${this.consts.createTopic}/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  mergePdfs(data: any, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.createTopic}/mergepdf/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateTopicImage(id: any, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.createTopic}/${id}/image`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateSubject(id: any, data: any) {
    return this.http
      .put(`${this.consts.subjectApiUrl}/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateSubjectImage(id: any, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.subjectApiUrl}/${id}/image`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateTopicVideos(id: any, data: any) {
    return this.http
      .put(`${this.consts.createTopic}/${id}/video`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateTopicDocs(id: any, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.createTopic}/${id}/document`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateVideoDesc(id: any, data: any) {
    return this.http
      .put(`${this.consts.updateVideoApiUrl}/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateDocDesc(id: any, data: any) {
    return this.http
      .put(`${this.consts.updateDocApiUrl}/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addActivity(data: any) {
    return this.http
      .post(this.consts.addActivity, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addActivityParentChild(username, data: any) {
    return this.http
      .post(`${this.consts.addActivity}?username=${username}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addDocinActivity(id, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.addActivity}/${id}/document`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchActivities(startDate: any, endDate: any) {
    return this.http.get(
      `${this.consts.addActivity}?finalDate[gte]=${startDate}&finalDate[lte]=${endDate}`,
      this.getHeaderOption()
    );
  }

  fetchActivitiesParentChild(startDate: any, endDate: any, username: any) {
    return this.http.get(
      `${this.consts.addActivity}?finalDate[gte]=${startDate}&finalDate[lte]=${endDate}&username=${username}`,
      this.getHeaderOption()
    );
  }

  fetchActivitiesTeachersStudents(
    startDate: any,
    endDate: any,
    grade: any,
    section: any,
    page
  ) {
    return this.http.get(
      `${this.consts.addActivity}/full?finalDate[gte]=${startDate}&finalDate[lte]=${endDate}&grade=${grade}&section=${section}&page=${page}`,
      this.getHeaderOption()
    );
  }

  fetchActivitiesTeachersStudentsImpersonate(
    startDate: any,
    endDate: any,
    grade: any,
    section: any,
    page,
    username
  ) {
    return this.http.get(
      `${this.consts.addActivity}/full?finalDate[gte]=${startDate}&finalDate[lte]=${endDate}&grade=${grade}&section=${section}&page=${page}&username=${username}`,
      this.getHeaderOption()
    );
  }

  fetchTeachersStudents(grade: any, section: any, page, username: any) {
    return this.http.get(
      `${this.consts.createAdminApiUrl}/students/full?grade=${grade}&section=${section}&page=${page}&username=${username}`,
      this.getHeaderOption()
    );
  }

  fetchWorksheetsTeachersDashboard() {
    return this.http.get(
      `${this.consts.addActivity}/document`,
      this.getHeaderOption()
    );
  }

  fetchWorksheetsTeachersDashboardImpersonate(username: any) {
    return this.http.get(
      `${this.consts.addActivity}/document?username=${username}`,
      this.getHeaderOption()
    );
  }

  dragDropActivity(data: any) {
    return this.http
      .put(this.consts.addActivity, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  sendMessage(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(this.consts.messageApiUrl, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  sendMessageParentChild(username, data: any) {
    return this.http
      .post(`${this.consts.messageApiUrl}?username=${username}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  receiveMessages(page) {
    return this.http.get(
      `${this.consts.messageApiUrl}/received?page=${page}`,
      this.getHeaderOption()
    );
  }

  receiveMessagesParentStudent(page, username) {
    return this.http.get(
      `${this.consts.messageApiUrl}/received?username=${username}&page=${page}`,
      this.getHeaderOption()
    );
  }

  receiveMessagesFilterFrom(page, from) {
    return this.http.get(
      `${this.consts.messageApiUrl}/received?page=${page}&from=${from}`,
      this.getHeaderOption()
    );
  }

  readMessage(id: any) {
    return this.http
      .put(`${this.consts.messageApiUrl}/read/${id}`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  readMessageParentChild(username, id: any) {
    return this.http
      .put(`${this.consts.messageApiUrl}/read/${id}?username=${username}`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  sentMessages(page) {
    return this.http.get(
      `${this.consts.messageApiUrl}/sent?page=${page}`,
      this.getHeaderOption()
    );
  }

  sentMessagesParentStudent(page, username) {
    return this.http.get(
      `${this.consts.messageApiUrl}/sent?username=${username}&page=${page}`,
      this.getHeaderOption()
    );
  }

  // https://room.aptence.com/bigbluebutton/api/create?allowStartStopRecording=true&attendeePW=ap&autoStartRecording=false&meetingID=random-855706&moderatorPW=mp&name=random-855706&record=false&voiceBridge=74915&welcome=%3Cbr%3EWelcome+to+%3Cb%3E%25%25CONFNAME%25%25%3C%2Fb%3E%21&checksum=317645b05ce142e622ee0be5d114eb6d2c7a7a08

  // createLiveSession(data: any) {
  //   return this.http.post(`${this.consts.liveSessionApiUrl}/create`, data)
  //     .pipe(
  //       catchError(this.errorHandler.handleError)
  //     );
  // }

  createLiveSession(id: any, data: any) {
    return this.http
      .post(`${this.consts.scheduleQuizApiUrl}/${id}/meeting`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createLiveSessionImpersonate(id: any, data: any, username: any) {
    return this.http
      .post(
        `${this.consts.scheduleQuizApiUrl}/${id}/meeting?username=${username}`,
        data
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateLiveSession(id: any, data: any) {
    return this.http
      .put(`${this.consts.liveSessionApiUrl}/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  startMeeting(id: any, data: any) {
    return this.http
      .post(`${this.consts.liveSessionApiUrl}/start/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  startMeetingImpersonate(id: any, data: any, username: any) {
    return this.http
      .post(
        `${this.consts.liveSessionApiUrl}/start/${id}?username=${username}`,
        data
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  joinMeeting(id: any, data: any) {
    return this.http
      .post(`${this.consts.liveSessionApiUrl}/join/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  joinMeetingImpersonate(id: any, data: any, username) {
    return this.http
      .post(
        `${this.consts.liveSessionApiUrl}/join/${id}?username=${username}`,
        data
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  endMeeting(id: any) {
    return this.http
      .post(`${this.consts.liveSessionApiUrl}/endMeeting/${id}`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  isMeetingRunning(id: any) {
    return this.http.get(
      `${this.consts.liveSessionApiUrl}/isMeetingRunning/${id}`,
      this.getHeaderOption()
    );
  }

  getMeetingInfo(id: any) {
    return this.http.get(
      `${this.consts.liveSessionApiUrl}/getMeetingInfo/${id}`,
      this.getHeaderOption()
    );
  }

  fetchParentsChildren() {
    return this.http.get(
      `${this.consts.parentsChildApiUrl}/student`,
      this.getHeaderOption()
    );
  }

  addParentsChild(data: any) {
    return this.http
      .post(`${this.consts.parentsChildApiUrl}/createstudent`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getProfileInfo() {
    // console.log(this.getHeaderOption())
    return this.http.get(
      this.consts.getUpdateProfileUrl,
      this.getHeaderOption()
    );
  }

  childGradeUpdateApiUrl(data: any) {
    return this.http
      .put(this.consts.childGradeUpdateApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createGame(id: any, data: any) {
    return this.http
      .put(`${this.consts.createTopic}/${id}/games`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addGameData(id: any, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.addGameApiUrl}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addGameDesc(id: any, data: any) {
    return this.http
      .put(`${this.consts.addGameApiUrl}/description/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getGameData(id: any) {
    return this.http.get(
      `${this.consts.addGameApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  createCourse(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(this.consts.courseApiUrl, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createCourseImpersonate(data: any, username) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(
        `${this.consts.courseApiUrl}?username=${username}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  createAptCourse(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(this.consts.aptCourseApiUrl, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  createExamCategory(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(this.consts.createExamCategoryApiUrl, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createExamSubCategory(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(this.consts.ExamSubCategoryApiUrl, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createExamSecondSubCategory(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(this.consts.ExamSecondSubCategory, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateCourse(id: any, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.courseApiUrl}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateAptCourse(id: any, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateExamCategory(id: any, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.createExamCategoryApiUrl}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateSubCategory(id: any, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.ExamSubCategoryApiUrl}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateSecondSubCategory(id: any, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.ExamSecondSubCategory}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addCourseParticipants(id: any, data) {
    return this.http
      .put(`${this.consts.courseApiUrl}/${id}/participants`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addAptCourseSchools(id: any, data) {
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/allowed`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  submitSubjectQuestion(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(this.consts.createSubjectQuestionApiUrl, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  submitAssesmentTopicDifficultyQuestion(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(
        `${this.consts.examApiUrl}/createassesmenttopicdifficultyquestion`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchAssesmentPaperReport(id: any, attemptId?: any) {
    if (attemptId) {
      return this.http.get(
        `${this.consts.examApiUrl}/assessmentpaperreport/${id}?answerPaperId=${attemptId}`,
        this.getHeaderOption()
      );
    } else {
      return this.http.get(
        `${this.consts.examApiUrl}/assessmentpaperreport/${id}`,
        this.getHeaderOption()
      );
    }
  }

  fetchAssesmentPaperSolutionReport(id: any, attemptId?: any) {
    if (attemptId) {
      return this.http.get(
        `${this.consts.examApiUrl}/assessmentpapersolution/${id}?answerPaperId=${attemptId}`,
        this.getHeaderOption()
      );
    } else {
      return this.http.get(
        `${this.consts.examApiUrl}/assessmentpapersolution/${id}`,
        this.getHeaderOption()
      );
    }
  }

  createAssesmentPaperQuestions(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(
        `${this.consts.examApiUrl}/createassesmentpaperquestions`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  submitAssesmentTopicQuestion(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(
        `${this.consts.examApiUrl}/assesmenttopicquestions`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateCourseFiles(id: any, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.courseApiUrl}/${id}/upload`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateAptCourseFiles(id: any, data) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/upload`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getCourseList(isAdmin?: boolean) {
    let url = `${this.consts.courseApiUrl}`;
    if (this.router.url.split('/')[1] == 'student') {
      const queryParams: any = { hideTestSeriesCourse: true };
      url += `?${new URLSearchParams(queryParams)}`;
    }
    return this.http.get(url, this.getHeaderOption());
  }

  getCourseListWithoutLogin() {
    return this.http.get(
      `${this.consts.courseApiUrl}/allcoursewithoutlogin`,
      this.getHeaderOption()
    );
  }

  getCourseFilteredListWithoutLogin(
    instructor,
    category,
    subcategory,
    secondsubcategory
  ) {
    return this.http.get(
      `${this.consts.courseApiUrl}/allcoursewithoutlogin?instructors=${instructor}&examCategory=${category}&examSubCategory=${subcategory}&examSecondarySubCategory=${secondsubcategory}`,
      this.getHeaderOption()
    );
  }

  getAptCourseList(page) {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}?page=${page}`,
      this.getHeaderOption()
    );
  }

  getExamCategoryList() {
    return this.http.get(
      `${this.consts.createExamCategoryApiUrl}/all`,
      this.getHeaderOption()
    );
  }

  getExamCategoryCourses(id) {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}/category/${id}`,
      this.getHeaderOption()
    );
  }

  getExamCategoryCoursesLoggedin(id) {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}/category/loggedin/${id}`,
      this.getHeaderOption()
    );
  }

  getExamCategoryListById(id, search?) {
    let url: any;

    if (search) {
      url = `${this.consts.createExamCategoryApiUrl}/${id}?search=${search}`;
    } else {
      url = `${this.consts.createExamCategoryApiUrl}/${id}`;
    }
    return this.http.get(url, this.getHeaderOption());
    // return this.http.get(
    //   `${this.consts.createExamCategoryApiUrl}/${id}`,
    //   this.getHeaderOption()
    // );
  }

  getExamSecondCategoryListById(id) {
    return this.http.get(
      `${this.consts.ExamSubCategoryApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  fetchSamplePaperDetailById(id) {
    return this.http.get(
      `${this.consts.samplePaperApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }
  fetchSamplePaperList(category, subcategory, secondsubcategory) {
    return this.http.get(
      `${this.consts.samplePaperApiUrl}?category=${category}&subCategory=${subcategory}&secondarySubCategory=${secondsubcategory}`,
      this.getHeaderOption()
    );
  }
  getExamSecondSubCategoryListById(id) {
    return this.http.get(
      `${this.consts.ExamSecondSubCategory}/${id}`,
      this.getHeaderOption()
    );
  }

  fetchLanguage() {
    return this.http.get(
      `${this.consts.languageApiUrl}`,
      this.getHeaderOption()
    );
  }
  createLanguage(data) {
    return this.http
      .post(`${this.consts.languageApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  findcourselist(data) {
    return this.http
      .post(`${this.consts.findcourseApiURL}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteLanguage(id) {
    return this.http
      .delete(`${this.consts.languageApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getAptCourseListFeatured(page) {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}?page=${page}&featured=true`,
      this.getHeaderOption()
    );
  }

  getAptCourseListTrending(page) {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}?page=${page}&trending=true`,
      this.getHeaderOption()
    );
  }

  getCourseListImpersonate(username: any) {
    return this.http.get(
      `${this.consts.courseApiUrl}?username=${username}`,
      this.getHeaderOption()
    );
  }

  aptCourseListFilter(
    page,
    instructor,
    skill,
    sortBy,
    enrolled,
    allowedSchool,
    featured,
    trending
  ) {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}?page=${page}&instructor=${instructor}&skill=${skill}&sort=${sortBy}&enrolled=${enrolled}&allowedSchools=${allowedSchool}&featured=${featured}&trending=${trending}`,
      this.getHeaderOption()
    );
  }

  // getCourseListAllUsers(page: number) {
  //   let child;
  //   this.switchchildService.childModeStatus.subscribe(data => child = data);
  //   if (this.isParent) {
  //     return this.http.get(`${this.consts.courseApiUrl}/all?page=${page}&username=${child}`, this.getHeaderOption());
  //   }
  //   else {
  //     return this.http.get(`${this.consts.courseApiUrl}/all?page=${page}`, this.getHeaderOption());
  //   }
  // }

  // getCourseFilteredList(instructor, category) {
  //   return this.http.get(`${this.consts.courseApiUrl}/filteredcourses/all?instructors=${instructor}&category=${category}`, this.getHeaderOption());
  // }

  getCourseFilteredList(
    instructor,
    category,
    subcategory,
    secondsubcategory,
    hideTestCourse?: any
  ) {
    return this.http.get(
      `${this.consts.courseApiUrl}?instructors=${instructor}&examCategory=${category}&examSubCategory=${subcategory}&examSecondarySubCategory=${secondsubcategory}&hideTestSeriesCourse=${hideTestCourse}`,
      this.getHeaderOption()
    );
  }

  getCourseFilteredListWithoutInstructor(
    category,
    subcategory,
    secondsubcategory
  ) {
    return this.http.get(
      `${this.consts.courseApiUrl}?examCategory=${category}&examSubCategory=${subcategory}&examSecondarySubCategory=${secondsubcategory}`,
      this.getHeaderOption()
    );
  }
  getBooksFilteredList(category, subcategory, secondsubcategory) {
    return this.http.get(
      `${this.consts.courseApiUrl}/globalbooks/all?category=${category}&subCategory=${subcategory}&secondSubCategory=${secondsubcategory}`,
      this.getHeaderOption()
    );
  }
  getBooksFilteredListWithoutLogin(category, subcategory, secondsubcategory) {
    return this.http.get(
      `${this.consts.courseApiUrl}/globalbooks/withoutlogin?category=${category}&subCategory=${subcategory}&secondSubCategory=${secondsubcategory}`,
      this.getHeaderOption()
    );
  }

  // getCourseFilteredListAllUsers(page: number, instructor, pricegte, pricelte, ageLower, ageUpper, skill) {
  //   let child;
  //   this.switchchildService.childModeStatus.subscribe(data => child = data);
  //   if (this.isParent) {
  //     return this.http.get(`${this.consts.courseApiUrl}/all?page=${page}&instructor=${instructor}&price[gte]=${pricegte}&price[lte]=${pricelte}&ageLower[gte]=${ageLower}&ageUpper[lte]=${ageUpper}&skill=${skill}&username=${child}`, this.getHeaderOption());
  //   }
  //   else {
  //     return this.http.get(`${this.consts.courseApiUrl}/all?page=${page}&instructor=${instructor}&price[gte]=${pricegte}&price[lte]=${pricelte}&ageLower[gte]=${ageLower}&ageUpper[lte]=${ageUpper}&skill=${skill}`, this.getHeaderOption());
  //   }
  // }

  getCourseById(id: number) {
    return this.http.get(
      `${this.consts.courseApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  getAllCourseMentors() {
    return this.http.get(
      `${this.consts.courseApiUrl}/all/instructors`,
      this.getHeaderOption()
    );
  }

  getAllCourseSkills() {
    return this.http.get(
      `${this.consts.courseApiUrl}/all/skills`,
      this.getHeaderOption()
    );
  }

  getAllAptCourseMentors() {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}/instructors`,
      this.getHeaderOption()
    );
  }

  getAllAptCourseSkills() {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}/skills`,
      this.getHeaderOption()
    );
  }

  addLiveClassInCourse(id: any, data: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/${id}/meeting`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addLiveClassInAptCourse(id: any, data: any) {
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/meeting`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  enrollInAptCourse(id: any) {
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/enroll`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  enrollInCourse(id: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/${id}/enroll`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }
  enrollInBook(id: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/${id}/enrollbook`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }
  fetchBooksByTagId(tag: any) {
    return this.http
      .get(
        `${this.consts.courseApiUrl}/fetchbookbytag/${tag}`,
        this.getHeaderOption()
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  fetchCoursesByTagId(tag: any) {
    return this.http
      .get(
        `${this.consts.courseApiUrl}/fetchcoursesbytag/${tag}`,
        this.getHeaderOption()
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  enrollSecondSubCategoryBooks(id: any) {
    return this.http
      .put(
        `${this.consts.courseApiUrl}/${id}/enrollSecondSubCategoryBooks`,
        null
      )
      .pipe(catchError(this.errorHandler.handleError));
  }
  enrollInSecondSubCategory(id: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/${id}/enrollSecondSubCategory`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getEnrolledCourseListParent(username, page: number) {
    return this.http.get(
      `${this.consts.courseApiUrl}?page=${page}&enrolled=true&username=${username}`,
      this.getHeaderOption()
    );
  }

  getEnrolledCourseList() {
    return this.http.get(
      `${this.consts.courseApiUrl}?enrolled=true`,
      this.getHeaderOption()
    );
  }

  getAptCourseEnrolledCourseList() {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}?enrolled=true`,
      this.getHeaderOption()
    );
  }

  getAllSamplePaperLists() {
    return this.http.get(
      `${this.consts.samplePaperApiUrl}`,
      this.getHeaderOption()
    );
  }

  getEnrolledAptCourseList(page) {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}?enrolled=true&page=${page}`,
      this.getHeaderOption()
    );
  }

  fetchCourseDetail(courseId) {
    return this.http.get(
      `${this.consts.courseApiUrl}/${courseId}`,
      this.getHeaderOption()
    );
  }

  fetchExamCategoryDetail(examCategoryId) {
    console.log(examCategoryId);
    return this.http.get(
      `${this.consts.createExamCategoryApiUrl}/${examCategoryId}`,
      this.getHeaderOption()
    );
  }

  getClassInfo(id: any) {
    return this.http.get(
      `${this.consts.liveSessionApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  getMeetingsList(username: any, startDate: any, endDate: any) {
    return this.http.get(
      `${this.consts.liveSessionApiUrl}?username=${username}&startDate[gte]=${startDate}&startDate[lte]=${endDate}`,
      this.getHeaderOption()
    );
  }

  getMeetingsListStudent(startDate: any, endDate: any) {
    return this.http.get(
      `${this.consts.liveSessionApiUrl}?startDate[gte]=${startDate}&startDate[lte]=${endDate}`,
      this.getHeaderOption()
    );
  }

  createappointment(username, data: any) {
    return this.http
      .post(`${this.consts.appointmentApiUrl}?username=${username}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createAssesmentPaper(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/createassesment`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  postAnswerImage(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/postanswerimage`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createInstructions(data: any) {
    return this.http
      .post(`${this.consts.examApiUrl}/instructions`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateInstruction(id: any, data: any) {
    return this.http
      .put(`${this.consts.examApiUrl}/instructions/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  findInstrctionsForPaperId(id) {
    return this.http.get(
      `${this.consts.examApiUrl}/instructions/${id}`,
      this.getHeaderOption()
    );
  }

  updateAssesmentPaper(data: any, id: any) {
    return this.http
      .put(`${this.consts.examApiUrl}/createassesment/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteAssesmentPaper(id: any) {
    return this.http
      .delete(`${this.consts.examApiUrl}/createassesment/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  searchAssesmentPaperByCourseId(id) {
    return this.http.get(
      `${this.consts.examApiUrl}/searchassesmentpaper/${id}`,
      this.getHeaderOption()
    );
  }
  fetchAssessmentPaperDetails(id) {
    return this.http.get(
      `${this.consts.examApiUrl}/fetchassessmentpaper/${id}`,
      this.getHeaderOption()
    );
  }

  assesmentPaperSubjects(id) {
    return this.http.get(
      `${this.consts.examApiUrl}/assesmentpapersubjects/${id}`,
      this.getHeaderOption()
    );
  }
  fetchAssesmentPaperQuestions(id: any, langId?: any, questionId?: any) {
    console.log(id, langId, questionId);
    if (langId == undefined && questionId == undefined) {
      return this.http.get(
        `${this.consts.examApiUrl}/assesmentpaperquestion/${id}`,
        this.getHeaderOption()
      );
    } else if (questionId && langId) {
      return this.http.get(
        `${this.consts.examApiUrl}/assesmentpaperquestion/${id}?language=${langId}&questionId=${questionId}`,
        this.getHeaderOption()
      );
    } else {
      return this.http.get(
        `${this.consts.examApiUrl}/assesmentpaperquestion/${id}?language=${langId}`,
        this.getHeaderOption()
      );
    }
  }

  fetchAssesmentSubjectDetail(id) {
    return this.http.get(
      `${this.consts.createSubjectQuestionApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  fetchAssesmentTopicDifficultyDetail(id) {
    return this.http.get(
      `${this.consts.examApiUrl}/getassesmenttopicdifficultyquestion/${id}`,
      this.getHeaderOption()
    );
  }

  fetchExistingExamDetails(id) {
    return this.http.get(
      `${this.consts.examApiUrl}/existingassesmenttest/${id}`,
      this.getHeaderOption()
    );
  }

  fetchAllAssessmentTest() {
    return this.http.get(
      `${this.consts.examApiUrl}/fetchassesmenttestlist`,
      this.getHeaderOption()
    );
  }

  fetchAllfilterAssessmentTest(category, subcategory, secondarySubCategory) {
    return this.http.get(
      `${this.consts.examApiUrl}/fetchfilterassesmenttestlist?category=${category}&subcategory=${subcategory}&sub2category=${secondarySubCategory}`,
      this.getHeaderOption()
    );
  }

  fetchQuestionsInCategory(course, subject, topic) {
    return this.http.get(
      `${this.consts.examApiUrl}/getallquestions/?course=${course}&subject=${subject}&topic=${topic}`,
      this.getHeaderOption()
    );
  }

  addMeetingInAppointment(id: any, data: any) {
    return this.http
      .post(`${this.consts.appointmentApiUrl}/meeting/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getMonthlyAppointments(username: any) {
    return this.http.get(
      `${this.consts.appointmentApiUrl}/?username=${username}`,
      this.getHeaderOption()
    );
  }

  getAppointmentsTeachersDashboard() {
    return this.http.get(
      `${this.consts.appointmentApiUrl}/dashboard`,
      this.getHeaderOption()
    );
  }

  getAllCourseInstructors() {
    return this.http.get(
      `${this.consts.instructorApiUrl}`,
      this.getHeaderOption()
    );
  }

  sectionsList() {
    return this.http.get(
      this.consts.sectionsListApiUrl,
      this.getHeaderOption()
    );
  }

  deleteCourse(id: any) {
    return this.http
      .delete(`${this.consts.courseApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteExamCategory(id: any) {
    return this.http
      .delete(`${this.consts.createExamCategoryApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteQuestion(id: any) {
    return this.http
      .delete(`${this.consts.examApiUrl}/deleteQuestion/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }
  deleteExamSubCategory(id: any) {
    return this.http
      .delete(`${this.consts.ExamSubCategoryApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }
  deleteExamSecondSubCategory(id: any) {
    return this.http
      .delete(`${this.consts.ExamSecondSubCategory}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  instituteSectionsList(schoolId) {
    const sectionsListApiUrl = `${this.consts.sectionsListApiUrl}?school=${schoolId}`;
    return this.http.get(sectionsListApiUrl, this.getHeaderOption());
  }

  assignToPaid(id: any) {
    return this.http
      .put(`${this.consts.createAdminApiUrl}/${id}/paid`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  assignToUnpaid(id: any) {
    return this.http
      .put(`${this.consts.createAdminApiUrl}/${id}/unpaid`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteStudent(id: any) {
    return this.http
      .delete(`${this.consts.createAdminApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteMessage(id: any) {
    return this.http
      .delete(`${this.consts.messageApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createZoomKey(data: any, username) {
    return this.http
      .post(`${this.consts.zoomKeyApiUrl}?username=${username}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getZoomKey(username) {
    return this.http.get(
      `${this.consts.zoomKeyApiUrl}?username=${username}`,
      this.getHeaderOption()
    );
  }

  updateZoomKey(data: any, username) {
    return this.http
      .put(`${this.consts.zoomKeyApiUrl}?username=${username}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getZoomSignature(id: any, role: any) {
    return this.http
      .post(`${this.consts.liveSessionApiUrl}/${id}/signature`, role)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getChildAnalyticsData(createdAtGte, createdAtLte) {
    return this.http.get(
      `${this.consts.analyticsApiUrl}?createdAt[gte]=${createdAtGte}&createdAt[lte]=${createdAtLte}`,
      this.getHeaderOption()
    );
  }

  startTracking(topic) {
    return this.http
      .post(this.consts.analyticsApiUrl, topic)
      .pipe(catchError(this.errorHandler.handleError));
  }

  endTracking(id: any) {
    return this.http
      .put(`${this.consts.analyticsApiUrl}/${id}`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  startMeetingTracking(data) {
    return this.http
      .post(this.consts.meetingTrackApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  endMeetingTracking(id: any) {
    return this.http
      .put(`${this.consts.meetingTrackApiUrl}/${id}`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getMeetingTrackingDataAllTime() {
    return this.http.get(
      `${this.consts.meetingTrackApiUrl}`,
      this.getHeaderOption()
    );
  }

  getMeetingTrackingDataThisWeek() {
    return this.http.get(
      `${this.consts.meetingTrackApiUrl}/thisweek`,
      this.getHeaderOption()
    );
  }

  getMeetingTrackingDataLastWeek() {
    return this.http.get(
      `${this.consts.meetingTrackApiUrl}/lastweek`,
      this.getHeaderOption()
    );
  }

  updateAptCourseFeatured(id, data) {
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/featured`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateAptCourseTrending(id) {
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/trending`, null)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateAptCourseRating(id, data) {
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/rating`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  bulkEnrollAptCourse(id, data) {
    return this.http
      .put(`${this.consts.aptCourseApiUrl}/${id}/bulkenroll`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getTrendingCourses() {
    return this.http.get(
      `${this.consts.aptCourseApiUrl}/trending`,
      this.getHeaderOption()
    );
  }

  getTrackingDataThisWeek() {
    return this.http.get(
      `${this.consts.analyticsApiUrl}/thisweek`,
      this.getHeaderOption()
    );
  }

  getTrackingDataLastWeek() {
    return this.http.get(
      `${this.consts.analyticsApiUrl}/lastweek`,
      this.getHeaderOption()
    );
  }

  sendUserContactReq(data) {
    return this.http
      .post(this.consts.contactReqUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteVideo(id: any) {
    return this.http
      .delete(`${this.consts.updateVideoApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteDocument(id: any) {
    return this.http
      .delete(`${this.consts.updateDocApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getAptCourseSubjectsByCourseId(id) {
    return this.http.get(
      `${this.consts.courseApiUrl}/${id}/subject`,
      this.getHeaderOption()
    );
  }

  createAptCourseSubjects(id, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(`${this.consts.aptCourseApiUrl}/${id}/subject`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createCourseSubjects(id, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(`${this.consts.subjectApiUrl}/course/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getAptCourseSubjectDeatilsBySubjectId(id) {
    return this.http.get(
      `${this.consts.subjectApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  getCourseSubjectDeatilsBySubjectId(id) {
    return this.http.get(
      `${this.consts.subjectApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  createTopic(subjectId, data: any) {
    return this.http
      .post(`${this.consts.createTopic}/subject/${subjectId}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createGrade(data: any) {
    return this.http
      .post(this.consts.gradeListApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createSection(data: any) {
    return this.http
      .post(this.consts.sectionsListApiUrl, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addImageinActivity(id, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.addActivity}/${id}/files`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteMeeting(id: any) {
    return this.http
      .delete(`${this.consts.liveSessionApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteActivity(id: any) {
    return this.http
      .delete(`${this.consts.addActivity}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchActivitySet(username, page) {
    return this.http.get(
      `${this.consts.addActivity}/set?username=${username}&page=${page}`,
      this.getHeaderOption()
    );
  }

  updateActivity(id, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.addActivity}/set/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteActivitySet(id: any) {
    return this.http
      .delete(`${this.consts.addActivity}/set/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getZoomMeetingInfo(id) {
    return this.http.get(
      `${this.consts.liveSessionApiUrl}/${id}/zoom`,
      this.getHeaderOption()
    );
  }

  assignCourseBatch(id: any, data) {
    return this.http
      .post(`${this.consts.aptCourseApiUrl}/${id}/batch`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteCourseBatch(id: any) {
    return this.http
      .delete(`${this.consts.aptCourseApiUrl}/batch/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  enrollStudentInCourse(id: any, username: any) {
    return this.http
      .put(
        `${this.consts.aptCourseApiUrl}/${id}/enroll?username=${username}`,
        null
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteGame(id) {
    return this.http
      .delete(`${this.consts.addGameApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createRole(data: any) {
    return this.http
      .post(`${this.consts.roleApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getRoles() {
    return this.http.get(`${this.consts.roleApiUrl}`, this.getHeaderOption());
  }

  getRoleDetails(id) {
    return this.http.get(
      `${this.consts.roleApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  createNewProposal(data: any) {
    return this.http
      .post(`${this.consts.teacherRequestApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getTeacherRequests() {
    return this.http.get(
      `${this.consts.getteacherRequestApiUrl}`,
      this.getHeaderOption()
    );
  }

  approveTeacherRequest(data: any) {
    return this.http
      .put(`${this.consts.assignteacherRequestApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createJobListing(data: any) {
    return this.http
      .post(`${this.consts.courseApiUrl}/joblisting`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  createCouponType(data: any) {
    return this.http
      .post(`${this.consts.courseApiUrl}/coupontype`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  createCoupon(data: any) {
    return this.http
      .post(`${this.consts.courseApiUrl}/coupon`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getCouponTypes() {
    return this.http.get(
      `${this.consts.courseApiUrl}/coupontype/all`,
      this.getHeaderOption()
    );
  }
  getJobListings() {
    return this.http.get(
      `${this.consts.courseApiUrl}/joblisting/all`,
      this.getHeaderOption()
    );
  }

  deleteJobListing(id) {
    return this.http
      .delete(`${this.consts.courseApiUrl}/deletejoblisting/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }
  deleteBook(id) {
    return this.http
      .delete(`${this.consts.courseApiUrl}/updateglobalbook/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }
  deleteSamplePaper(id) {
    return this.http
      .delete(`${this.consts.samplePaperApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteCouponType(id) {
    return this.http
      .delete(`${this.consts.courseApiUrl}/deletecoupontype/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getSerpKeyWordsList() {
    return this.http.get(
      `${this.consts.searchJobListingApiUrl}/savekeywords/`,
      this.getHeaderOption()
    );
  }

  updateSerpKeyWords(id, data) {
    return this.http
      .put(`${this.consts.searchJobListingApiUrl}/savekeywords/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createSerpKeyWords(data) {
    return this.http
      .post(`${this.consts.searchJobListingApiUrl}/savekeywords/`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  deleteSerpKeyWords(id) {
    return this.http
      .delete(`${this.consts.searchJobListingApiUrl}/savekeywords/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getJobListingById(id) {
    return this.http.get(
      `${this.consts.courseApiUrl}/joblistingdetails/${id}`,
      this.getHeaderOption()
    );
  }

  getFilteredJobListing(category, qualification, ageLower, ageUpper, state) {
    return this.http.get(
      `${this.consts.courseApiUrl}/filteredjoblisting/all?category=${category}&eligibility=${qualification}&age[gte]=${ageLower}&age[lte]=${ageUpper}&state=${state}`,
      this.getHeaderOption()
    );
  }

  // getFilteredCourses(category) {
  //   return this.http.get(`${this.consts.courseApiUrl}?category=${category}`, this.getHeaderOption());
  // }

  getCouponDetailById(id) {
    return this.http.get(
      `${this.consts.courseApiUrl}/coupon/${id}`,
      this.getHeaderOption()
    );
  }
  getCouponTypeDetailById(id) {
    return this.http.get(
      `${this.consts.courseApiUrl}/coupontype/${id}`,
      this.getHeaderOption()
    );
  }

  updateJobListing(id, data: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/joblisting/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateCouponType(id, data: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/coupontype/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  applyCoupon(data: any) {
    return this.http
      .post(`${this.consts.courseApiUrl}/applycoupon`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  enrollMegatestseries(data: any) {
    return this.http
      .post(`${this.consts.megatestseriesApiUrl}/enrollMegatestseries`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getJobListingSyllabus(id) {
    return this.http.get(
      `${this.consts.courseApiUrl}/joblisting/${id}/syllabus`,
      this.getHeaderOption()
    );
  }

  getCouponDetailByCode(code) {
    return this.http.get(
      `${this.consts.courseApiUrl}/coupon/code/${code}`,
      this.getHeaderOption()
    );
  }

  getCouponListingByCouponType(id, tag) {
    return this.http.get(
      `${this.consts.courseApiUrl}/coupontype/${id}/coupon?tag=${tag}`,
      this.getHeaderOption()
    );
  }

  createJobListingSyllabus(data: any) {
    return this.http
      .post(`${this.consts.courseApiUrl}/joblistingsyllabus/`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateJobListingSyllabus(id, data: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/joblistingsyllabus/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateCoupon(id, data: any) {
    return this.http
      .put(`${this.consts.courseApiUrl}/coupon/${id}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  deleteJobListingSyllabus(id) {
    return this.http
      .delete(`${this.consts.courseApiUrl}/joblistingsyllabus/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }
  deleteCoupon(id) {
    return this.http
      .delete(`${this.consts.courseApiUrl}/coupon/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchDocsByCourseId(id: any) {
    return this.http.get(
      `${this.consts.courseApiUrl}/${id}/documents`,
      this.getHeaderOption()
    );
  }

  updateBookTracking(data: any) {
    return this.http
      .put(`${this.consts.analyticsApiUrl}/coursebook`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchUserCourseBookDetails(id: any, book) {
    return this.http.get(
      `${this.consts.analyticsApiUrl}/coursebook/${id}/?book=${book}`,
      this.getHeaderOption()
    );
  }

  addGlobalBook(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(`${this.consts.courseApiUrl}/globalbook`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  addSamplePaper(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(`${this.consts.samplePaperApiUrl}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  updateSamplePaper(data: any, id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.samplePaperApiUrl}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }

  addGlobalMultipleChapter(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(
        `${this.consts.courseApiUrl}/globalmultiplechapter`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchGlobalBooks() {
    return this.http.get(
      `${this.consts.courseApiUrl}/globalbooks/all`,
      this.getHeaderOption()
    );
  }
  fetchGlobalBooksWithoutLogin() {
    return this.http.get(
      `${this.consts.courseApiUrl}/globalbooks/withoutlogin`,
      this.getHeaderOption()
    );
  }

  fetchGlobalBookDetail(id) {
    return this.http.get(
      `${this.consts.courseApiUrl}/globalbookdetail/${id}`,
      this.getHeaderOption()
    );
  }

  updateBookDetails(id, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(
        `${this.consts.courseApiUrl}/updateglobalbook/${id}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  createCity(data: any) {
    return this.http
      .post(`${this.consts.cityApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  createState(data: any) {
    return this.http
      .post(`${this.consts.stateApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getStates() {
    return this.http.get(`${this.consts.stateApiUrl}`, this.getHeaderOption());
  }

  getTags() {
    return this.http.get(`${this.consts.tagsApiUrl}`, this.getHeaderOption());
  }

  getCities() {
    return this.http.get(`${this.consts.cityApiUrl}`, this.getHeaderOption());
  }

  getStateCities(state) {
    return this.http.get(
      `${this.consts.cityApiUrl}?state=${state}`,
      this.getHeaderOption()
    );
  }

  createQualification(data: any) {
    return this.http
      .post(`${this.consts.courseApiUrl}/qualifications`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
  createTag(data: any) {
    return this.http
      .post(`${this.consts.tagsApiUrl}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }

  getQualifications() {
    return this.http.get(
      `${this.consts.courseApiUrl}/qualifications`,
      this.getHeaderOption()
    );
  }

  createmegatestseries(data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .post(
        `${this.consts.megatestseriesApiUrl}/createMegatestseries`,
        data,
        httpOptions
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateMegaTestSeries(data: any, id: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    };
    return this.http
      .put(`${this.consts.updateMegatestseriesApiUrl}/${id}`, data, httpOptions)
      .pipe(catchError(this.errorHandler.handleError));
  }
  getmegatestseries() {
    return this.http.get(
      `${this.consts.megatestseriesApiUrl}`,
      this.getHeaderOption()
    );
  }
  getSingleMegatestseries(id: any) {
    return this.http.get(
      `${this.consts.getSingleMegatestseriesApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }
  deleteMegatestseries(id: any) {
    return this.http
      .delete(`${this.consts.deleteMegatestseriesApiUrl}/${id}`)
      .pipe(catchError(this.errorHandler.handleError));
  }

  fetchtestseriesDetailsById(id) {
    return this.http.get(
      `${this.consts.megatestseriesApiUrl}/testseries/${id}`,
      this.getHeaderOption()
    );
  }

  fetchAllfiltermegatestseries(category, subcategory, secondarySubCategory) {
    return this.http.get(
      `${this.consts.megatestseriesApiUrl}/filtermegatestseries?examCategory=${category}&examSubCategory=${subcategory}&examSecondarySubCategory=${secondarySubCategory}`,
      this.getHeaderOption()
    );
  }

  getVideoSignedURLbyId(id) {
    return this.http.get(
      `${this.consts.updateVideoApiUrl}/signedurl/${id}`,
      this.getHeaderOption()
    );
  }

  updateChapterName(payload: any) {
    return this.http
      .put(`${this.consts.updateChapterDescription}/${payload.bookId}`, {
        documentId: payload.chapterId,
        description: payload.description,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateSingleChapterName(payload: any) {
    return this.http
      .put(`${this.consts.updateSingleChapterDescription}/${payload.bookId}`, {
        documentId: payload.chapterId,
        description: payload.description,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }
  /*Get selected question for preview */
  getSelectedQuestions(id: any) {
    return this.http.get(
      `${this.consts.getSelectedQuestionApiUrl}/${id}/questions`,
      this.getHeaderOption()
    );
  }

  /*delete selected question from preview */
  deleteSelectedQuestions(payload: any) {
    return this.http.put(
      `${this.consts.getSelectedQuestionApiUrl}/remove-question/${payload.id}`,
      { paperId: payload.paperId },
      this.getHeaderOption()
    );
  }

  /*get all question from bank */

  getAllQuestions(id: any) {
    return this.http.get(
      `${this.consts.getSelectedQuestionApiUrl}/${id}/all-questions`,
      this.getHeaderOption()
    );
  }

  /*Get all languages */
  getAllLanguages(id?: any) {
    return this.http.get(
      `${this.consts.getAllLanguagesApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }

  // Export all questions
  private getHeaderOptionForCsv() {
    const token = this.cookie.get('_l_a_t');
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }).set('Access-Control-Allow-Origin', '*'),
      responseType: 'blob' as 'json',
    };
  }
  exportAllQuestions(courseId: any, subjectId: any, topicId: any) {
    const url = `${this.consts.exportQuestions}?course=${courseId}&subject=${subjectId}&topic=${topicId}`;
    console.log('url', url);

    this.http.get(url, this.getHeaderOptionForCsv()).subscribe(
      (blob: any) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'questions.csv'; // Adjust the file name as needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error('There was a problem with the HTTP operation:', error);
      }
    );
  }

  getNumberOfattempts(id: any) {
    return this.http.get(
      `${this.consts.getTotalAttemptsApiUrl}/${id}`,
      this.getHeaderOption()
    );
  }
  // exportAllQuestions(courseId: any, subjectId: any, topicId: any) {

  //   return this.http.get(
  //     `${this.consts.exportQuestions}?course=${courseId}&subject=${subjectId}&topic=${topicId}`,
  //     this.getHeaderOption()
  //   );

  // }

  fetchAllAttemptsPaperReport(id: any) {
    return this.http.get(
      `${this.consts.fetchAllAttemptsPaperReport}/${id}`,
      this.getHeaderOption()
    );
  }

  getAllReportedQuestions() {
    return this.http.get(
      `${this.consts.fetchAllReportedQuestions}`,
      this.getHeaderOption()
    );
  }

  submitReportOption(data: any) {
    console.log(data);
    return this.http
      .post(`${this.consts.submitReportedQuestion}`, data)
      .pipe(catchError(this.errorHandler.handleError));
  }
}
