<!-- <ngx-extended-pdf-viewer
[src]="pdfSrc"
[textLayer]="true"
[showHandToolButton]="true"
[showPresentationModeButton]="true"
[(page)]="page"
[(pageLabel)]="pageLabel"
[contextMenuAllowed]="allowContextMenu"
[showPrintButton]="false"
[showRotateButton]="false"
[showDownloadButton]="false"
[showPresentationModeButton]="false"
[showDownloadButton]="false"
[showOpenFileButton]="false"
[showHandToolButton]="false"
[showBookmarkButton]="false"
>
</ngx-extended-pdf-viewer> -->
<ngx-extended-pdf-viewer
  [src]="pdfSrc"
  [textLayer]="true"
  [showToolbar]="true"
  [showHandToolButton]="true"
  [showDownloadButton]="false"
  [showOpenFileButton]="false"
  [showHandToolButton]="false"
  [showBookmarkButton]="false"
  [showPrintButton]="false"
  [showRotateButton]="false"
  [showDownloadButton]="false"
  [showPresentationModeButton]="false"
  [enablePrint]="false"
  [contextMenuAllowed]="allowContextMenu"
  [(page)]="page"
  [(pageLabel)]="pageLabel"
  (pageChange)="pageChange($event)"
  [(sidebarVisible)]="sidebarOpen"
>
</ngx-extended-pdf-viewer>

<ng-template #customSidebar>
  <div id="sidebarContainer" style="top: 0px; background-color: #002333">
    <div id="additionalSidebarContainer">
      <div id="toolbarSidebarLeft">
        <button
          type="button"
          id="viewThumbnail"
          title="Show Thumbnails"
          data-l10n-id="thumbs"
          class="toolbarButton">
          <span data-l10n-id="thumbs_label">Thumbnails</span
          ><svg viewBox="0 0 24 24" style="width: 20px; height: 20px">
            <path
              fill="currentColor"
              d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          id="viewOutline"
          title="Show Document Outline (double-click to expand/collapse all items)"
          data-l10n-id="document_outline"
          class="toolbarButton toggled">
          <span data-l10n-id="document_outline_label">Document Outline</span
          ><svg viewBox="0 0 24 24" style="width: 20px; height: 20px">
            <path
              fill="currentColor"
              d="M3,9H17V7H3V9M3,13H17V11H3V13M3,17H17V15H3V17M19,17H21V15H19V17M19,7V9H21V7H19M19,13H21V11H19V13Z"
            ></path>
          </svg>
          </button>

        <div style="color: #fff;" class="m-2">Content</div>
          <button
          type="button"
          id="viewAttachments"
          title="Show Attachments"
          data-l10n-id="attachments"
          hidden=""
          class="toolbarButton"
          disabled="">
          <span data-l10n-id="attachments_label">Attachments</span
          ><svg viewBox="0 0 24 24" style="width: 20px; height: 20px">
            <path
              fill="currentColor"
              d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
            ></path>
          </svg>
        </button>

          <button
          id="viewLayers"
          title="Show Layers (double-click to reset all layers to the default state)"
          data-l10n-id="layers"
          class="toolbarButton"
        >
          <span data-l10n-id="layers_label">Layers</span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 4.233 4.233"
            style="width: 20px; height: 20px"
          >
            <path
              d="M.15 2.992c-.198.1-.2.266-.002.365l1.604.802a.93.93 0 00.729-.001l1.602-.801c.198-.1.197-.264 0-.364l-.695-.348c-1.306.595-2.542 0-2.542 0m-.264.53l.658-.329c.6.252 1.238.244 1.754 0l.659.329-1.536.768zM.15 1.935c-.198.1-.198.265 0 .364l1.604.802a.926.926 0 00.727 0l1.603-.802c.198-.099.198-.264 0-.363l-.694-.35c-1.14.56-2.546.001-2.546.001m-.264.53l.664-.332c.52.266 1.261.235 1.75.002l.659.33-1.537.768zM.15.877c-.198.099-.198.264 0 .363l1.604.802a.926.926 0 00.727 0l1.603-.802c.198-.099.198-.264 0-.363L2.481.075a.926.926 0 00-.727 0zm.43.182L2.117.29l1.538.769-1.538.768z"
            ></path>
          </svg>
        </button>
      </div>
      <div id="toolbarSidebarRight" style="color: #fff;">
        <div id="toolbarContainer">
          <div id="toolbarViewer">
            <div id="toolbarViewerLeft">
              <pdf-page-number></pdf-page-number>
            </div>
          </div>
        </div>
        <pdf-page-number></pdf-page-number>
      </div>
    </div>

    <pdf-sidebar-content></pdf-sidebar-content>
    <div id="sidebarResizer" class="hidden"></div>
  </div>
</ng-template>

<!-- <ng-template #fancySidebar>
  <div id="sidebarContainer" style="top:31px;background-color:goldenrod">
    <div id="additionalSidebarContainer">
      <div id="toolbarSidebar">
        <div id="outlineOptionsContainer" class="splitToolbarButton toggled">
          <button style="background-color: red; height:100%;width: 34%;border:0;margin:0;padding:0"
            type="button" id="viewThumbnail"
            class="toolbarButton"
            data-l10n-id="thumbs">
            <span data-l10n-id="thumbs_label">Thumbnails</span>
          </button>
          <button
            style="background-color: green; height:100%;width: 35%;border:0;margin:0;padding:0"
            type="button"
            id="viewOutline"
            class="toolbarButton"
            data-l10n-id="document_outline">
            <span data-l10n-id="document_outline_label">Document Outline</span>
          </button>
          <button
            style="background-color: blue; height:100%;width: 34%;border:0;margin:0;padding:0"
            type="button"
            id="viewAttachments"
            class="toolbarButton"
            data-l10n-id="attachments">
            <span data-l10n-id="attachments_label">Attachments</span>
          </button>
        </div>
      </div>
    </div>
    <pdf-sidebar-content></pdf-sidebar-content>
    <div id="sidebarResizer" class="hidden"></div>
  </div>
</ng-template> -->
<!-- [src]="pdfSrc"
[textLayer]="true"
[showHandToolButton]="true"
[showPresentationModeButton]="true"
[(page)]="page"
[(pageLabel)]="pageLabel"
[contextMenuAllowed]="allowContextMenu"
[showPrintButton]="false"
[showRotateButton]="false"
[showDownloadButton]="false"
[showPresentationModeButton]="false"
[showDownloadButton]="false"
[showOpenFileButton]="false"
[showHandToolButton]="false"
[showBookmarkButton]="false" -->

<!-- <div class="container">
    aa -->

<!-- <pdf-viewer [src]="pdfSrc"
            [page]="page"
            [show-all]="true"
            [stick-to-page]="true"
            style="display: block;"
    ></pdf-viewer> -->
<!-- {{pdfSrc}} -->
<!-- https://aptcourse.s3-ap-south-1.amazonaws.com/media/topic/course_3_61e5162e4ec60361ec3c2b48.pdf -->
<!-- <ng2-pdfjs-viewer
        pdfSrc="pdfSrc"
        [externalWindow]="true"
        [downloadFileName]="pdfSrc"
        [openFile]="false"
        [viewBookmark]="false"
        [download]="false"
    ></ng2-pdfjs-viewer> -->
<!-- <button class="semi-transparent-button" style="position: absolute; left: 10px; bottom: 10px;" (click)="download()">Download PDF</button> -->
<!-- </div> -->

<!-- <div>
    <button (click)="switchShowAll()">showAll</button>
    <button (click)="switchSticky()">sticky</button>
    <div *ngIf="stickToPage">
      <button (click)="setPage(-1)">prev</button>
      <button (click)="setPage(1)">next</button>
    </div>
</div>
<pdf-viewer [src]="pdfSrc"
            [(page)]="page"
            [show-all]="true"
            [stick-to-page]="true"
            [render-text]="false"
            style="display: block;">
</pdf-viewer> -->
