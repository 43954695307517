import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NetworkRequestInterceptor } from './core/interceptors/network-request.interceptor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomePageComponent } from './modules/home/components/home-page/home-page.component';
import { PdfViewerComponent } from './modules/home/components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MediaPlayerComponent } from './modules/home/components/media-player/media-player.component';
// videogular
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { ProgressBarModule } from 'angular-progress-bar';
// import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

export let InjectorInstance: Injector;

// import { KalturaModule } from 'angular2-kaltura';
@NgModule({
  declarations: [
    AppComponent,
    PdfViewerComponent,
    MediaPlayerComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    ProgressBarModule,
    NgxQRCodeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    PdfJsViewerModule,
    NgxUiLoaderModule,
    CarouselModule,
    RouterModule,
    CarouselModule,

    // NgxUiLoaderHttpModule.forRoot({
    //   showForeground: true,
    //   exclude: ["/api/"]
    // }),
    // ProgressbarModule
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkRequestInterceptor,
      multi: true,
    },
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
