import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/http/error-handler.service';
import { ConstantsService } from 'src/app/config/constants.service';
import { Profile, ProfileAdapter } from '../adaptors/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private _userProfile$: BehaviorSubject<Profile>;
  public _userProfile: Profile;


  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private adapter: ProfileAdapter,
    private consts: ConstantsService
  ) {
    this._userProfile$ = new BehaviorSubject<Profile>(JSON.parse(localStorage.getItem('userProfile')));
   }

   get userProfile$() {
    return this._userProfile$.asObservable();
}

setUserProfileValue(data) {
    this._userProfile$.next(data);
}

refreshProfileData() {
    return new Observable(observer => {
        this.http.get<Array<object>>(this.consts.getUpdateProfileUrl)
            .subscribe(
                data => {
                    observer.next(this.adapter.adapt(data['data']));
                },
                error => {
                    observer.error('failed');
                });
    })
}

setProfileData(data) {
    this.setUserProfileValue(data);
}

getProfileData() {
    return this.http.get(this.consts.getUpdateProfileUrl)
        .pipe(
            catchError(this.errorHandler.handleError)
        );
}

updateProfileData(data) {
    return this.http.put(this.consts.getUpdateProfileUrl, data)
        .pipe(
            catchError(this.errorHandler.handleError)
        );
}

sendOtpReq(data) {
    return this.http.post(this.consts.forgotPasswordUrl, data)
        .pipe(
            catchError(this.errorHandler.handleError)
        );
}

verifyOtpReq(data) {
    return this.http.post(this.consts.verifyOptReqUrl, data)
        .pipe(
            catchError(this.errorHandler.handleError)
        );
}

updateProfilePicture(data, profileId) {
    const picUpdateUrl = this.consts.picUpdateUrl + profileId + '/';
    let httpOptions = {
        headers: new HttpHeaders({
            'Accept': '*/*'
        })
    };
    return this.http.put(picUpdateUrl, data, httpOptions)
        .pipe(
            catchError(this.errorHandler.handleError)
        )
}


changePasswordReq(data) {
    return this.http.post(this.consts.changePasswordUrl, data)
        .pipe(
            catchError(this.errorHandler.handleError)
        )
}
}
