import { Component, ElementRef, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Document, Page } from 'react-pdf';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { AdminService } from 'src/app/core/services/admin.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, OnDestroy {

  @HostListener('document:keyup', ['$event'])
  onKeyUP(e) {
    console.log(e);
    if (e.key == 'PrintScreen') {
      // e.preventDefault();
      navigator.clipboard.writeText('');
      // navigator.clipboard.write
      // document.execCommand('paste')
    }

  }


  //   document.addEventListener('keyup', (e) => {
  //     if (e.key == 'PrintScreen') {
  //     	if (noScreenshot)
  //     	{
  //         navigator.clipboard.writeText('');

  //       }
  //     }
  // });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminservice: AdminService,
    private cookie: CookieService
  ) { }
  src = 'http://www.africau.edu/images/default/sample.pdf';
  pdfSrc;
  page = 1;
  sidebarOpen: boolean = true;
  stickToPage: boolean = false;
  showAll: boolean = true;
  public allowContextMenu = false;
  courseId;
  bookId;

  public pageLabel!: string;
  download() {
    // const blob = this.pdfSrc;
    let pdfname = this.pdfSrc.split('/').pop().split('.').shift();
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.pdfSrc;
    link.download = pdfname;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  pageChange(event) {
    console.log("event", event);
    this.page = event;
    // if (this.bookId) {
    //   this.updateUserBookPage();
    // }
  }


  // updateUserBookPage() {
  //   const formData = {
  //     course: this.courseId,
  //     book: this.bookId,
  //     page: this.page
  //   }
  //   console.log("formdata", formData);
  //   this.adminservice.updateBookTracking(formData).subscribe(
  //     data => {
  //       console.log("page updated ", data);
  //     },
  //     error => {
  //       console.log("error ", error);
  //     }
  //   )
  // }

  // getUserBookPage() {
  //   this.adminservice.fetchUserCourseBookDetails(this.courseId, this.bookId).subscribe(
  //     data => {
  //       console.log("book page details ", data);
  //       if (data) {
  //         this.page = data['pageNumber'];
  //       }
  //     },
  //     error => {
  //       console.log("error ", error);
  //     }
  //   )
  // }

  ngOnDestroy(): void{
    const pageNumber = this.page
    this.cookie.set(`${this.bookId}`, this.page.toString());
  }

  ngOnInit(): void {
    //@ts-ignore
    // this.myPdfViewer.page = 2;
    this.route.queryParams.subscribe(
      params => {
        this.pdfSrc = params.pdf;
        // setTimeout(() => {
        //   this.page = 4;
        // }, 500);
        if (params.bookId) {
          this.bookId = params.bookId;
          // console.log(this.bookId);
        }
        if (params.course) {
          this.courseId = params.course;
          setTimeout(() => {
            // this.getUserBookPage();
          }, 500);
        }
      }
    )
    this.page = Number(this.cookie.get(this.bookId))
  }

  onAfterLoad(event: any) {
  }

  switchSticky() {
    this.stickToPage = !this.stickToPage;
  }

  switchShowAll() {
    this.showAll = !this.showAll;
  }

  setPage(num: number) {
    this.page += num;
    console.log(this.page);
  }

}
