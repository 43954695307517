import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private activeModal: NgbActiveModal,
  ) { }

  imgSrc;

  ngOnInit(): void {
    // this.route.queryParams.subscribe(
    //   params => {
    //     this.imgSrc = params.img;
    //   }
    // )
  }


}
