import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { ConstantsService } from 'src/app/config/constants.service';
import { ErrorHandlerService } from '../http/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private errorHandler: ErrorHandlerService,
    private consts: ConstantsService
  ) { }

  login(data: any) {
    return this.http.post(this.consts.loginApiUrl, data)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  signup(data: any) {
    return this.http.post(this.consts.signupApiUrl, data)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }

  signupTeacher(data: any) {
    return this.http.post(this.consts.signupTeacherApiUrl, data)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }


  logout() {

    this.cookie.deleteAll('/');
    this.cookie.deleteAll();
    sessionStorage.clear();
    localStorage.clear();
    location.replace('/');

    // return this.http.get(`${this.consts.logoutApiUrl}`)
    //   .pipe(
    //     catchError(this.errorHandler.handleError)
    //   );
  }
}
