<div class="websiteFooter prepBgColor">
  <div class="row">
    <div class="col-lg-3">
      <img src="/assets/home/NK_logo.jpeg" alt="" style="height: 100px;">
      <h2 class="text-white contact">CONTACT</h2>
      <p class="text-white footerEmail">
        info.nkonlinetest@gmail.com</p>
    </div>
    <div class="col-lg-6">
      <div class="footerNav">
        <div><a href="javascript:void(0)">Home</a></div>
        <div><a href="javascript:void(0)">Courses Jobs/Vacancies</a></div>
        <div><a href="javascript:void(0)">e-books</a></div>
        <div><a href="javascript:void(0)">Printed Books</a></div>
        <div><a href="javascript:void(0)">Test series</a></div>
        <div><a href="javascript:void(0)">PYQPs</a></div>
        <div><a href="javascript:void(0)">Jobs/Vacancies</a></div>
        <div><a [routerLink]="['/privacy']">privacy</a></div>
        <div><a [routerLink]="['/term&condition']">term&condition</a></div>
      </div>
      <p class="copyRight text-white text-center">© 2022 Nk online test</p>
    </div>
    <div class="col-lg-3">
      <h2 class="text-white getInTouch">Get in touch</h2>
      <p class="text-white getInTouchMsg">Stay informed on how you can make difference</p>
      <div class="input-group enterYourEmail">
        <input type="text" class="form-control border border-white" placeholder="Enter your email" aria-label="Recipient's username" aria-describedby="basic-addon2">
        <div class="input-group-append">
          <span class="input-group-text bg-white border border-white" id="basic-addon2"><img src="assets/home/inputBoxIcon.png" alt=""></span>
        </div>
      </div>
    </div>
  </div>
</div>
