import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { UserAuthGuard } from './core/guards/user-auth.guard';
import { ContactUsComponent } from './modules/home/components/contact-us/contact-us.component';
import { CoursesComponent } from './modules/home/components/courses/courses.component';
import { HomePageComponent } from './modules/home/components/home-page/home-page.component';
import { ImageViewerComponent } from './modules/home/components/image-viewer/image-viewer.component';
import { MediaPlayerComponent } from './modules/home/components/media-player/media-player.component';
import { PdfViewerComponent } from './modules/home/components/pdf-viewer/pdf-viewer.component';
import { TeacherRequestFormComponent } from './modules/home/components/teacher-request-form/teacher-request-form.component';
import { PrivacyPolicyComponent } from './modules/home/components/privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './modules/home/components/term-condition/term-condition.component';


const routes: Routes = [
  // {path:'', component: HomePageComponent},
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'viewpdf', component: PdfViewerComponent
  },
  {
    path: 'viewImg', component: ImageViewerComponent
  },
  {
    path: 'media-player', component: MediaPlayerComponent
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  // },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  // },
  {
    path: 'contact-us', component: ContactUsComponent
  },
  {
    path: 'privacy', component: PrivacyPolicyComponent
  },
  {
    path: 'term&condition', component: TermConditionComponent
  },
  // {
  //   path: 'teacher-registration', component: TeacherRequestFormComponent
  // },
  // {
  //   path: 'courses', component: CoursesComponent
  // },
  {
    path: 'student',
    loadChildren: () => import('./modules/student/student.module').then(m => m.StudentModule), canLoad: [UserAuthGuard]
  },
  {
    path: 'employee',
    loadChildren: () => import('./modules/employee/employee.module').then(m => m.EmployeeModule),
  },
  {
    path: 'superAdmin',
    loadChildren: () => import('./modules/super-admin/super-admin.module').then(m => m.SuperAdminModule), canLoad: [AuthGuard]
  },
  {
    path: 'instructor',
    loadChildren: () => import('./modules/instructor/instructor.module').then(m => m.InstructorModule),
  },
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
